import md5 from 'md5';
import { Lesson } from '../../../api';

const state = {
    lessons: [],
    isLoadingLesson: false,
    lessonsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    lessonBody: {
        lesson_id: '',
        title: '',
        text: '',
    },
};
const mutations = {
    SET_LESSONS(state, lessons) {
        state.lessons = lessons;
    },
    SET_IS_LOADING_LESSON(state, status) {
        state.isLoadingLesson = status;
    },
    SET_LESSONS_PAGINATION(state, pagination) {
        state.lessonsPagination = pagination;
    },
    SET_LESSON_BODY(state, body) {
        state.lessonBody = body;
    },
};
const actions = {
    async getLessons({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_LESSON', true);

            let params = {
                fields: ['$all', { test: ['$all'] }, { lesson: ['$all'] }],
                order: [['created_at', 'desc']],
                page: payload?.page || state.lessonsPagination.page,
                limit: state.lessonsPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Lesson.getList(params);

            let lessons = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                lessons = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.lessonsPagination.pageSize);
            }

            commit('SET_LESSONS', lessons);
            commit('SET_LESSONS_PAGINATION', {
                ...state.lessonsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_LESSON', false);
        }
    },
    async postNewLesson({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_LESSON', true);

            const lessonBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await Lesson.createOne(lessonBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_LESSON', false);
        }
    },
    async putOneLesson({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_LESSON', true);
            const id = payload.id;
            const lessonBody = {
                ...payload.body,
                // title: payload?.title?.trim() || '',
            };

            const res = await Lesson.updateOne(id, lessonBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_LESSON', false);
        }
    },
    async deleteOneLesson({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_LESSON', true);
            const res = await Lesson.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_LESSON', false);
        }
    },
    async deleteMultiSubLessons({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_LESSON', true);
            const res = await Lesson.deleteMulti(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_LESSON', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
