import { Course } from '../../../api';

const state = {
    courses: [],
    coursesOne: {},
    isLoadingCourse: false,
    coursesPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    courseBody: {
        title: '',
        thumbnail: '',
        note: '',
        array_hashtag: [],
        course_settings: [],
        materials: [],
    },
};
const mutations = {
    SET_COURSES(state, courses) {
        state.courses = courses;
    },
    SET_COURSES_ONE(state, coursesOne) {
        state.coursesOne = coursesOne;
    },
    SET_IS_LOADING_COURSE(state, status) {
        state.isLoadingCourse = status;
    },
    SET_COURSES_PAGINATION(state, pagination) {
        state.coursesPagination = pagination;
    },
    SET_COURSE_BODY(state, body) {
        state.courseBody = body;
    },
};
const actions = {
    async getCourses({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.coursesPagination.page,
                limit: payload?.limit || state.coursesPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Course.getList(params);

            let courses = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                courses = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.coursesPagination.pageSize);
            }

            commit('SET_COURSES', courses);
            commit('SET_COURSES_PAGINATION', {
                ...state.coursesPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
    async getCoursesOne({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE', true);
            const res = await Course.getOne(payload, {
                fields: [
                    '$all',
                    {
                        course_chapters: [
                            'id',
                            'course_id',
                            'chapter_id',
                            {
                                course_sections: [
                                    'id',
                                    'section_id',
                                    'course_chapter_id',
                                    {
                                        section: [
                                            'id',
                                            'chapter_id',
                                            'lesson_id',
                                            'test_id',
                                            'form_id',
                                            { lesson: ['id', 'title'] },
                                            { test: ['id', 'title'] },
                                            { form: ['id', 'title'] },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        course_settings: [
                            'id',
                            'required',
                            'assign_everyone',
                            'start_date',
                            'end_date',
                            { course_members: ['id', 'user_id', { user: ['id', 'email'] }] },
                        ],
                    },
                ],
            });

            let coursesOne = {};
            // let totalItems = 0;
            // let totalPages = 0;
            if (res?.results?.object) {
                coursesOne = res?.results?.object;
                // totalItems = res?.pagination?.total;
                // totalPages = Math.ceil(totalItems / state.coursesPagination.pageSize);
            }

            commit('SET_COURSES_ONE', coursesOne);
            // commit('SET_COURSES_PAGINATION', {
            //     ...state.coursesPagination,
            //     ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            // });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
    async postNewCourse({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE', true);

            const courseBody = {
                title: payload.title.trim() || '',
                thumbnail: payload.thumbnail || '',
                note: payload.note || '',
                description: payload.description || '',
                array_hashtag: payload.array_hashtag || [],
                course_settings: payload.course_settings || [],
                materials: payload.materials || [],
                status: payload.status || false,
            };

            const res = await Course.createOne(courseBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
    async putOneCourse({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE', true);
            const id = payload.id;
            const courseBody = {
                title: payload.title.trim() || '',
                thumbnail: payload.thumbnail || '',
                note: payload.note || '',
                description: payload.description || '',
                array_hashtag: payload.array_hashtag || [],
                course_settings: payload.course_settings || [],
                materials: payload.materials || [],
                status: payload.status,
            };

            const res = await Course.updateOne(id, courseBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
    async deleteOneCourse({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_COURSE', true);
            const res = await Course.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
    async deleteMultiCourses({ commit, state }, arr) {
        try {
            commit('SET_IS_LOADING_COURSE', true);
            const res = await Course.deleteMulti(arr);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
