import md5 from 'md5';
import { Company } from '../../../api';
import * as cmsCompanyAPI from '../../../api/cmsCompanyAPI';

const state = {
    companys: [],
    isLoadingCompany: false,
    companyPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    companyBody: {
        company_code: '',
        company_name: '',
        email: '',
        department: '',
        zipcode: '',
        status: '',
        note: '',
    },
};
const mutations = {
    SET_COMPANYS(state, companys) {
        state.companys = companys;
    },
    SET_IS_LOADING_COMPANY(state, status) {
        state.isLoadingCompany = status;
    },
    SET_COMPANY_PAGINATION(state, pagination) {
        state.companyPagination = pagination;
    },
    SET_COMPANY_BODY(state, body) {
        state.companyBody = body;
    },
};
const actions = {
    async getCompanys({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COMPANY', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.companyPagination.page,
                limit: state.companyPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Company.getList(params);

            let companys = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                companys = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.companyPagination.pageSize);
            }

            commit('SET_COMPANYS', companys);
            commit('SET_COMPANY_PAGINATION', {
                ...state.companyPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_COMPANY', false);
        }
    },
    async postNewCompany({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COMPANY', true);

            const companyBody = {
                ...payload,
                company_code: payload?.company_code?.trim() || '',
                company_name: payload?.company_name?.trim() || '',
                email: payload?.email?.trim() || '',
                department: payload?.department?.trim() || '',
                zipcode: payload?.zipcode?.trim() || '',
                status: payload?.status || '',
                note: payload?.note?.trim() || '',
            };

            const res = await Company.createOne(companyBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COMPANY', false);
        }
    },
    async putOneCompany({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COMPANY', true);
            const id = payload.id;
            const companyBody = {
                // ...payload,
                company_code: payload?.company_code?.trim() || '',
                company_name: payload?.company_name?.trim() || '',
                email: payload?.email?.trim() || '',
                department: payload?.department?.trim() || '',
                zipcode: payload?.zipcode?.trim() || '',
                status: payload?.status || null,
                note: payload?.note?.trim() || '',
            };

            const res = await Company.updateOne(id, companyBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COMPANY', false);
        }
    },
    async deleteOneCompany({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_COMPANY', true);
            const res = await Company.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COMPANY', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
