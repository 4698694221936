import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const update = async (id, payload) => exportResults(await HTTP.put(`${ROUTE_API.USER}/${id}`, payload));

export const changePassword = async (payload) => exportResults(await HTTP.put(ROUTE_API.CHANGE_PASSWORD, payload));

export const changePasswordMaster = async (payload) =>
    exportResults(await HTTP.put(ROUTE_API.CHANGE_PASSWORD_MASTER, payload));

export const setPassword = async (payload) => exportResults(await HTTP.post(ROUTE_API.SET_PASSWORD, payload));

export const findOne = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.USER}/${id}`, {
            params: convertParams(params),
        }),
    );

export const getProfile = async (params) =>
    exportResults(
        await HTTP.get(ROUTE_API.USER_PROFILE, {
            params: convertParams(params),
        }),
    );
