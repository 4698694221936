import md5 from 'md5';
import { CourseOfUser } from '../../../api';
import * as cmsCoursesOfUserAPI from '../../../api/cmsCoursesOfUserAPI';

const state = {
    coursesOfUsers: [],
    isLoadingCoursesOfUser: false,
    coursesOfUserPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    coursesOfUserBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: '',
        role: '',
    },
};
const mutations = {
    SET_COURSES_OF_USERS(state, coursesOfUsers) {
        state.coursesOfUsers = coursesOfUsers;
    },
    SET_IS_LOADING_COURSES_OF_USER(state, status) {
        state.isLoadingCoursesOfUser = status;
    },
    SET_COURSES_OF_USER_PAGINATION(state, pagination) {
        state.coursesOfUserPagination = pagination;
    },
    SET_COURSES_OF_USER_BODY(state, body) {
        state.coursesOfUserBody = body;
    },
};
const actions = {
    async getCoursesOfUsers({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSES_OF_USER', true);

            let params = {
                fields: ['$all'],
                order: [['created_at_unix_timestamp', 'desc']],
                page: payload?.page || state.coursesOfUserPagination.page,
                limit: state.coursesOfUserPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await CourseOfUser.findOne(payload.id, params);

            let coursesOfUsers = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                coursesOfUsers = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.coursesOfUserPagination.pageSize);
            }

            commit('SET_COURSES_OF_USERS', coursesOfUsers);
            commit('SET_COURSES_OF_USER_PAGINATION', {
                ...state.coursesOfUserPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_COURSES_OF_USER', false);
        }
    },
    async postNewCoursesOfUser({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSES_OF_USER', true);

            const coursesOfUserBody = {
                ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                password: md5(payload?.password?.trim()) || '',
            };

            const res = await cmsCoursesOfUserAPI.createOne(coursesOfUserBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_COURSES_OF_USER', false);
        }
    },
    async putOneCoursesOfUser({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSES_OF_USER', true);
            const id = payload.id;
            const coursesOfUserBody = {
                // ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                gender: payload?.gender || '',
                role: payload?.role || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await cmsCoursesOfUserAPI.updateOne(id, coursesOfUserBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_COURSES_OF_USER', false);
        }
    },
    async deleteOneCoursesOfUser({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_COURSES_OF_USER', true);
            const res = await cmsCoursesOfUserAPI.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_COURSES_OF_USER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
