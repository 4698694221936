import { cmsNotificationAPI, cmsPushNotificationAPI } from '../../../api';
import moment from 'moment';

const state = {
    notifications: [],
    isLoading: false,
    notificationPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_NOTIFICATIONS(state, values) {
        state.notifications = values;
    },
    SET_IS_LOADING(state, status) {
        state.isLoading = status;
    },
    SET_NOTIFICATION_PAGINATION(state, pagination) {
        state.notificationPagination = pagination;
    },
};
const actions = {
    async getNotifications({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.notificationPagination.page,
                limit: state.notificationPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [`${payload?.field}`]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await cmsPushNotificationAPI.getList(params);

            let users = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                users = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.notificationPagination.pageSize);
            }

            commit('SET_NOTIFICATIONS', users);
            commit('SET_NOTIFICATION_PAGINATION', {
                ...state.notificationPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    async postNewNoti({ commit }, payload) {
        try {
            const body = {
                ...payload,
                message_title: payload?.message_title?.trim() || '',
                sending_unix_timestamp: moment(payload?.sending_unix_timestamp).valueOf() || '',
                frequency: payload?.frequency || 'ONE_TIME',
                type: 'GROUP',
            };

            await cmsPushNotificationAPI.createOne(body);

            return { isSuccess: true, message: 'Create success notice' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async putNoti({ commit }, payload) {
        try {
            const body = {
                ...payload,
                message_title: payload?.message_title?.trim() || '',
                sending_unix_timestamp: moment(payload?.sending_unix_timestamp).valueOf() || '',
                frequency: payload?.frequency || 'ONE_TIME',
                type: 'GROUP',
            };

            await cmsPushNotificationAPI.updateOne(payload?.id, body);

            return { isSuccess: true, message: 'Update success notice' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async deleteOne({ commit }, id) {
        try {
            await cmsPushNotificationAPI.deleteOne(id);
            return { isSuccess: true, message: 'Delete success notice' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async pushNoti({ commit }, id) {
        try {
            await cmsPushNotificationAPI.pushNoti(id);
            return { isSuccess: true, message: 'Push success notice' };
        } catch (error) {
            return { isSuccess: true, message: error };
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
