export const mixinTranslate = {
    methods: {
        notificationTranslateText(text) {
            switch (text) {
                case 'REGULAR_EVERY_7_DAYS':
                    return 'REGULAR EVERY 7 DAYS';

                case 'REGULAR_EVERY_30_DAYS':
                    return 'REGULAR EVERY 30 DAYS';
                case 'ONE_TIME':
                    return 'ONE TIME';
                default:
                    return '';
            }
        },
    },
};

export default mixinTranslate;
