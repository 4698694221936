import { FAQ } from '../../../api';
import { TYPE_FAQ } from '../../../constants';

const state = {
    faqList: [],
    isLoading: false,
    faqPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_FAQ_LIST(state, values) {
        state.faqList = values;
    },
    SET_IS_LOADING(state, status) {
        state.isLoading = status;
    },
    SET_FAQ_PAGINATION(state, pagination) {
        state.faqPagination = pagination;
    },
};
const actions = {
    async getFaqList({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.faqPagination.page,
                limit: state.faqPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [`${payload?.field}`]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await FAQ.getList(params);

            let list = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                list = res?.results?.objects?.rows?.filter((item) => item.type === TYPE_FAQ.FUNCTION);
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.faqPagination.pageSize);
            }

            commit('SET_FAQ_LIST', list);
            commit('SET_FAQ_PAGINATION', {
                ...state.faqPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    async postNewFAQ({ commit }, payload) {
        try {
            const body = { ...payload };

            await FAQ.createOne(body);

            return { isSuccess: true, message: 'Create successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async putFAQ({ commit }, payload) {
        try {
            const body = { ...payload };

            await FAQ.updateOne(payload?.id, body);

            return { isSuccess: true, message: 'Update successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async deleteFAQ({ commit }, id) {
        try {
            await FAQ.deleteOne(id);
            return { isSuccess: true, message: 'Delete successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
