import md5 from 'md5';
import { Employee } from '../../../api';
import * as cmsEmployeeAPI from '../../../api/cmsEmployeeAPI';

const state = {
    employees: [],
    isLoadingEmployee: false,
    employeePagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    employeeBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: '',
        role: '',
    },
};
const mutations = {
    SET_EMPLOYEES(state, employees) {
        state.employees = employees;
    },
    SET_IS_LOADING_EMPLOYEE(state, status) {
        state.isLoadingEmployee = status;
    },
    SET_EMPLOYEE_PAGINATION(state, pagination) {
        state.employeePagination = pagination;
    },
    SET_EMPLOYEE_BODY(state, body) {
        state.employeeBody = body;
    },
};
const actions = {
    async getEmployees({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_EMPLOYEE', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.employeePagination.page,
                limit: state.employeePagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Employee.getList(params);

            let employees = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                employees = res?.results?.objects?.rows;
                totalItems = res?.results?.objects?.count;
                totalPages = Math.ceil(totalItems / state.employeePagination.pageSize);
            }

            commit('SET_EMPLOYEES', employees);
            commit('SET_EMPLOYEE_PAGINATION', {
                ...state.employeePagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_EMPLOYEE', false);
        }
    },
    async postNewEmployee({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_EMPLOYEE', true);

            const employeeBody = {
                ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                password: md5(payload?.password?.trim()) || '',
            };

            const res = await Employee.createOne(employeeBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_EMPLOYEE', false);
        }
    },
    async putOneEmployee({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_EMPLOYEE', true);
            const id = payload.id;
            const employeeBody = {
                // ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                gender: payload?.gender || '',
                role: payload?.role || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await Employee.updateOne(id, employeeBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_EMPLOYEE', false);
        }
    },
    async deleteOneEmployee({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_EMPLOYEE', true);
            const res = await Employee.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_EMPLOYEE', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
