import axios from 'axios';
import app from '../main';

const accessToken = localStorage.getItem('accessToken');
const refreshToken = localStorage.getItem('refreshToken');

const axiosClient = axios.create({
    baseURL: process.env.VUE_APP_URL,
    headers: {
        'content-type': 'application/json',
        Authorization: accessToken ? `Bearer ${accessToken}` : '',
    },
});

// Add a request interceptor
axiosClient.interceptors.request.use(
    function (config) {
        const accessToken = localStorage.getItem('accessToken');
        // Do something before request is sent
        if (accessToken) {
            config.headers = {
                Authorization: `Bearer ${accessToken}`,
            };
        }
        return config;
    },
    function (error) {
        // Do something with request error
        return Promise.reject(error);
    },
);

axiosClient.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        const originalConfig = err.config;

        if (err.response) {
            // Access Token was expired
            if (err.response.status === 401 && !originalConfig._retry) {
                originalConfig._retry = true;

                try {
                    const res = await axiosClient.post('/auth/refresh', {
                        refresh_token: refreshToken,
                    });
                    app.$store.dispatch('updateAccessAndRefresh', res.data.results.object);
                    return axiosClient(originalConfig);
                } catch (_error) {
                    if (_error.response.data.code === 409) {
                        app.$store.dispatch('logout');
                    }
                    return Promise.reject(_error);
                }
            }
        }

        return Promise.reject(err);
    },
);

export const HTTP = axiosClient;
