import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const getList = async (params) =>
    exportResults(
        await HTTP.get(ROUTE_API.NOTIFICATION, {
            params: convertParams(params),
        }),
    );

export const findOne = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.NOTIFICATION}/${id}`, {
            params: convertParams(params),
        }),
    );

export const seen = async (id) => exportResults(await HTTP.get(`${ROUTE_API.NOTIFICATION}/seen/${id}`));

export const seenAll = async () => exportResults(await HTTP.get(`${ROUTE_API.NOTIFICATION}/read_all`));
