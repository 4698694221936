<template>
    <a-layout-sider
        collapsible
        class="sider-primary"
        breakpoint="lg"
        collapsed-width="0"
        width="250px"
        :collapsed="sidebarCollapsed"
        @collapse="$emit('toggleSidebar', !sidebarCollapsed)"
        :trigger="null"
        :class="['ant-layout-sider-' + sidebarColor, 'ant-layout-sider-' + sidebarTheme]"
        theme="light"
        :style="{ backgroundColor: 'transparent' }"
    >
        <router-link to="/dashboard">
            <div class="brand">
                <img
                    src="../../../assets/images/logo.jpg"
                    alt="logo"
                    width="100%"
                    :style="{ height: 'auto', marginRight: 0 }"
                />
            </div>
        </router-link>
        <hr />

        <!-- Sidebar Navigation Menu -->
        <a-menu theme="light" mode="inline">
            <a-menu-item key="1">
                <v-card class="mx-auto sidebar__menu" width="300">
                    <v-list nav dense v-if="isMaster">
                        <v-list-item-group v-model="selectedItemMaster" mandatory>
                            <router-link :to="url" v-for="([title, icon, url], index) in master" :key="index">
                                <v-list-item :key="index">
                                    <v-list-item-icon>
                                        <v-icon dense v-text="icon"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title v-text="title"></v-list-item-title>
                                </v-list-item>
                            </router-link>
                        </v-list-item-group>
                    </v-list>

                    <v-divider></v-divider>

                    <v-list nav dense v-if="isManager">
                        <v-list-item-group v-model="selectedItemManager" mandatory>
                            <v-list-item
                                :to="child.length === 0 ? url : ''"
                                v-for="([title, icon, url, child], index) in cms"
                                :key="index"
                                v-if="child.length === 0"
                                link
                            >
                                <v-list-item-icon>
                                    <v-icon dense v-text="icon"></v-icon>
                                </v-list-item-icon>
                                <v-list-item-title v-text="title"></v-list-item-title>
                            </v-list-item>
                            <v-list-group v-else>
                                <template #prependIcon>
                                    <v-list-item-icon>
                                        <v-icon dense v-text="icon"></v-icon>
                                    </v-list-item-icon>
                                </template>
                                <template v-slot:activator>
                                    <v-list-item-content>
                                        <v-list-item-title>{{ title }}</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                <v-list-item
                                    :to="urlChild"
                                    v-for="([titleChild, iconChild, urlChild], i) in child"
                                    :key="i"
                                    link
                                    class="ml-5"
                                >
                                    <v-list-item-icon>
                                        <v-icon v-text="iconChild"></v-icon>
                                    </v-list-item-icon>
                                    <v-list-item-title v-text="titleChild"></v-list-item-title>
                                </v-list-item>
                            </v-list-group>
                        </v-list-item-group>
                    </v-list>
                </v-card>
            </a-menu-item>
        </a-menu>
        <div class="btn-group">
            <a-button class="aside-logout" type="danger" @click="onLogout">
                <span class="font-bold text-light">{{ $t('log_out') }}</span>
            </a-button>
        </div>
        <f-notification-dialog
            class="dialogNoti"
            :isShowDialog="isShowDialogLogOut"
            width="500px"
            :title="$t('log_out')"
            :content="$t('confirm_log_out')"
            :actions="actionsLogout"
        />
    </a-layout-sider>
    <!-- / Main Sidebar -->
</template>

<script>
import { reactive, toRefs } from 'vue';
import { MailOutlined, AppstoreOutlined, SettingOutlined, HistoryOutlined } from '@ant-design/icons-vue';
import { checkIsMaster, checkIsManager } from '../../../utils';

export default {
    components: {
        MailOutlined,
        AppstoreOutlined,
        SettingOutlined,
        HistoryOutlined,
    },
    props: {
        // Sidebar collapsed status.
        sidebarCollapsed: {
            type: Boolean,
            default: false,
        },

        // Main sidebar color.
        sidebarColor: {
            type: String,
            default: 'primary',
        },

        // Main sidebar theme : light, white, dark.
        sidebarTheme: {
            type: String,
            default: 'light',
        },
    },
    data() {
        return {
            selectedItemMaster: 0,
            selectedItemManager: 0,
            // [ [title, icon, url, child_url, all_url] ]
            cms: [
                [this.$t('table_title.Dashboard'), 'mdi-home', '/dashboard', [], ['/dashboard']],
                [
                    this.$t('table_title.User_Group'),
                    'mdi-account-circle',
                    '',
                    [
                        [this.$t('table_title.User'), 'mdi-account-multiple-outline', '/user'],
                        [this.$t('table_title.User_Folder'), 'mdi-folder', '/user_folder'],
                    ],
                    ['/user', '/user_folder', '/courses-of-user', '/chapters-of-course'],
                ],
                [
                    this.$t('table_title.Course_Group'),
                    'fa-solid fa-book-open',
                    '',
                    [
                        [this.$t('table_title.Course'), 'fa-solid fa-book', '/course'],
                        [this.$t('table_title.Course_Folder'), 'mdi-folder', '/course_folder'],
                    ],
                    ['/course', '/course_folder'],
                ],
                [this.$t('table_title.Chapter'), 'fa-solid fa-layer-group', '/chapter', [], ['/chapter', '/sections']],
                [this.$t('table_title.Login_History'), 'mdi-history', '/login-history', [], ['/login-history']],
                [this.$t('table_title.Notification'), 'mdi mdi-bell', '/notification', [], ['/notification']],
                [this.$t('table_title.Batching_Process'), 'mdi mdi-file-document', '/excel', [], ['/excel']],
                [this.$t('table_title.User_Guide'), 'fa-solid fa-circle-info', '/user-guide', [], ['/user-guide']],
            ],
            master: [
                [this.$t('table_title.Dashboard'), 'mdi-home', '/dashboard', [], ['/dashboard']],
                [this.$t('table_title.Employee'), 'fa-solid fa-people-roof', '/employee', [], ['/employee']],
                [this.$t('table_title.Company'), 'fa-solid fa-building', '/company', [], ['/company', '/manager']],
                [this.$t('table_title.Login_History'), 'mdi-history', '/login-history', [], ['/login-history']],
                [this.$t('table_title.FAQ'), 'fa-solid fa-circle-question', '/faq-crud', [], ['/faq-crud']],
                [this.$t('table_title.Tutorial'), 'fa-solid fa-circle-info', '/tutorial', [], ['/tutorial']],
            ],
            // dialog log out
            isShowDialogLogOut: false,
            actionsLogout: [],
            isMaster: checkIsMaster(this.$store.getters.getUserInfo),
            isManager: checkIsManager(this.$store.getters.getUserInfo),
        };
    },
    mounted() {
        this.activeSidebar();
    },
    watch: {
        '$route.path': function () {
            this.activeSidebar();
        },
    },
    methods: {
        activeSidebar() {
            if (this.isMaster) {
                this.master.forEach(([title, icon, url, child_url, all_url], index) => {
                    const condition = all_url.find((item) => item.includes(this.$route.path));
                    if (condition) {
                        this.selectedItemMaster = index;
                    }
                });
            } else if (this.isManager) {
                this.cms.forEach(([title, icon, url, child_url, all_url], index) => {
                    const condition = all_url.find((item) => item.includes(this.$route.path));
                    if (condition) {
                        this.selectedItemManager = index;
                    }
                });
            }
        },
        onLogout() {
            this.isShowDialogLogOut = true;
            this.actionsLogout = [
                {
                    title: this.$t('cancel'),
                    function: this.cancelLogout,
                },
                {
                    title: this.$t('yes'),
                    function: this.agreeLogout,
                },
            ];
        },
        agreeLogout() {
            this.isShowDialogLogOut = false;
            this.$store.dispatch('logout');
        },
        cancelLogout() {
            this.isShowDialogLogOut = false;
        },
    },
    setup() {
        const state = reactive({
            rootSubmenuKeys: ['sub1', 'sub2', 'sub4'],
            openKeys: ['sub1'],
            selectedKeys: [],
        });
        const onOpenChange = (openKeys) => {
            const latestOpenKey = openKeys.find((key) => state.openKeys.indexOf(key) === -1);
            if (state.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                state.openKeys = openKeys;
            } else {
                state.openKeys = latestOpenKey ? [latestOpenKey] : [];
            }
        };
        return {
            ...toRefs(state),
            onOpenChange,
        };
    },
};
</script>

<style lang="scss">
.dialogNoti {
    position: absolute;
}

.v-application ol,
.v-application ul {
    padding-left: 0;
}

.v-application--is-ltr {
    .v-list-group--no-action.v-list-group--sub-group > .v-list-group__items > .v-list-item.items {
        padding-left: 44px;
    }
}

.btn-group {
    margin-top: auto;

    & button {
        margin-top: 10px !important;
        width: 100%;
    }
}

.sidebar__menu {
    & .v-list-item {
        padding: 10px 15px !important;
    }
    & i {
        font-size: 20px !important;
    }
    & i.fa-chevron-down {
        font-size: 16px !important;
    }
    & .v-list-item__icon {
        min-width: 24px !important;
    }
}
</style>
