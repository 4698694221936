import API from './api';
import { convertParams, exportResults } from '../utils/index';
import { HTTP } from '../config/http-common';

export default class ChaptersOfCourse extends API {
    getListChapterOfCourse = async (id, user_id, params) =>
        exportResults(
            await HTTP.get(`${this.ROUTE}/${id}/${user_id}`, {
                params: convertParams(params),
            }),
        );
}
