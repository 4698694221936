import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import * as _ from 'lodash';
import { checkIsManager, checkIsMaster, isMobile } from '../utils';

Vue.use(VueRouter);

const routesMain = [
    {
        path: '*',
        component: () => import('../views/CMS/404.vue'),
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        component: () => import('../views/CMS/Sign-In.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/sign-in/:company_code',
        name: 'SignIn',
        component: () => import('../views/CMS/Sign-In.vue'),
        meta: { requiresAuth: false },
    },
    {
        path: '/admin',
        name: 'Admin',
        component: () => import('../views/CMS/Sign-In.vue'),
        meta: { requiresAuth: false },
    },
    // {
    //     path: '/sign-up/:company_code',
    //     name: 'SignUp',
    //     component: () => import('../views/CMS/Sign-Up.vue'),
    //     meta: { layoutClass: 'layout-sign-up', requiresAuth: false },
    // },
    {
        path: '/forgot/:company_code',
        name: 'ForgotPassword',
        component: () => import('../views/CMS/Forgot.vue'),
        meta: { layoutClass: 'layout-sign-up', requiresAuth: false },
    },
    {
        path: '/reset/:company_code',
        name: 'ResetPassword',
        component: () => import('../views/CMS/Reset.vue'),
        meta: { layoutClass: 'layout-sign-up', requiresAuth: false },
    },
    {
        path: '/login_by_ms365/redirect',
        name: 'LoginBy365',
        component: () => import('../views/Web/Login365.vue'),
        meta: { layoutClass: 'layout-sign-up', requiresAuth: false },
    },
    {
        path: '/',
        name: 'HOME',
        meta: { requiresAuth: true },
    },

    // ------------------------ CMS ------------------------
    {
        path: '/dashboard',
        name: 'Dashboard',
        layout: 'dashboard',
        component: () => import('../views/CMS/Dashboard.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/company',
        name: 'Company',
        layout: 'dashboard',
        component: () => import('../views/CMS/Company.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Company',
                    },
                ];
            },
        },
    },
    {
        path: '/employee',
        name: 'Employee',
        layout: 'dashboard',
        component: () => import('../views/CMS/Employee.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Employee',
                    },
                ];
            },
        },
    },
    {
        path: '/manager',
        name: 'Manager',
        layout: 'dashboard',
        component: () => import('../views/CMS/Manager.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Company',
                        to: '/company',
                    },
                    {
                        text: 'Manager',
                    },
                ];
            },
        },
    },
    {
        path: '/user',
        name: 'User',
        layout: 'dashboard',
        component: () => import('../views/CMS/User.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'User',
                    },
                ];
            },
        },
    },
    {
        path: '/courses-of-user',
        name: 'Courses of User',
        layout: 'dashboard',
        component: () => import('../views/CMS/CoursesOfUser.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'User',
                        to: '/user',
                    },
                    {
                        text: 'User_Courses',
                    },
                ];
            },
        },
    },
    {
        path: '/chapters-of-course',
        name: 'Chapters Of Course',
        layout: 'dashboard',
        component: () => import('../views/CMS/ChaptersOfCourse.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                const user_id = route.query.user_id;
                return [
                    {
                        text: 'User',
                        to: '/user',
                    },
                    {
                        text: 'User_Courses',
                        to: `/courses-of-user?user_id=${user_id}`,
                    },
                    {
                        text: 'Course_Chapters',
                    },
                ];
            },
        },
    },
    {
        path: '/records-of-test',
        name: 'RecordsOfTest',
        layout: 'dashboard',
        component: () => import('../views/CMS/RecordsOfTest.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                const user_id = route.query.user_id;
                const course_id = route.query.course_id;
                return [
                    {
                        text: 'User',
                        to: '/user',
                    },
                    {
                        text: 'User_Courses',
                        to: `/courses-of-user?user_id=${user_id}`,
                    },
                    {
                        text: 'Course_Chapters',
                        to: `/chapters-of-course?user_id=${user_id}&course_id=${course_id}`,
                    },
                    {
                        text: 'Test_Records',
                    },
                ];
            },
        },
    },
    {
        path: '/user_folder',
        name: 'UserFolder',
        layout: 'dashboard',
        component: () => import('../views/CMS/UserFolder.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'User_Folder',
                    },
                ];
            },
        },
    },
    {
        path: '/course',
        name: 'Course',
        layout: 'dashboard',
        component: () => import('../views/CMS/Course.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Course',
                    },
                ];
            },
        },
    },
    {
        path: '/course_folder',
        name: 'Course folder',
        layout: 'dashboard',
        component: () => import('../views/CMS/CourseFolder.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Course_Folder',
                    },
                ];
            },
        },
    },
    {
        path: '/chapter',
        name: 'Chapter',
        layout: 'dashboard',
        component: () => import('../views/CMS/Chapter.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Chapter',
                    },
                ];
            },
        },
    },
    {
        path: '/sections',
        name: 'Section',
        layout: 'dashboard',
        component: () => import('../views/CMS/Section.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                const user_id = route.query.user_id;
                return [
                    {
                        text: 'Chapter',
                        to: '/chapter',
                    },
                    {
                        text: 'Section',
                    },
                ];
            },
        },
    },
    {
        path: '/Profile',
        name: 'Profile',
        layout: 'dashboard',
        component: () => import('../views/CMS/Profile.vue'),
        meta: { layoutClass: 'layout-profile', requiresAuth: true },
    },
    {
        path: '/login-history',
        name: 'LoginHistory',
        layout: 'dashboard',
        component: () => import('../views/CMS/LoginHistory.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Login_History',
                    },
                ];
            },
        },
    },
    {
        path: '/notification',
        name: 'Notification',
        layout: 'dashboard',
        component: () => import('../views/CMS/Notification.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Notification',
                    },
                ];
            },
        },
    },
    {
        path: '/excel',
        name: 'ExportExcel',
        layout: 'dashboard',
        component: () => import('../views/CMS/ExportExcel.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Batching_Process',
                    },
                ];
            },
        },
    },
    {
        path: '/test-record-detail',
        name: 'TestRecordDetail',
        layout: 'dashboard',
        component: () => import('../views/CMS/TestRecordDetail.vue'),
        meta: { requiresAuth: true },
    },
    // {
    //     path: '/faq',
    //     name: 'FAQ',
    //     layout: 'dashboard',
    //     component: () => import('../views/CMS/FAQ.vue'),
    //     meta: {
    //         requiresAuth: true,
    //         breadCrumb(route) {
    //             return [
    //                 {
    //                     text: 'FAQ',
    //                 },
    //             ];
    //         },
    //     },
    // },
    {
        path: '/faq-crud',
        name: 'FAQCRUD',
        layout: 'dashboard',
        component: () => import('../views/CMS/FAQCRUD.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'FAQ',
                    },
                ];
            },
        },
    },
    {
        path: '/user-guide',
        name: 'UserGuide',
        layout: 'dashboard',
        component: () => import('../views/CMS/UserGuide.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'User_Guide',
                    },
                ];
            },
        },
    },
    {
        path: '/user-guide/:id',
        name: 'UserGuideDetail',
        layout: 'dashboard',
        component: () => import('../views/CMS/UserGuideDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/tutorial',
        name: 'Tutorial',
        layout: 'dashboard',
        component: () => import('../views/CMS/Tutorial.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Tutorial',
                    },
                ];
            },
        },
    },
    {
        path: '/records-of-form',
        name: 'RecordsOfForm',
        layout: 'dashboard',
        component: () => import('../views/CMS/RecordsOfForm.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                const user_id = route.query.user_id;
                const course_id = route.query.course_id;
                return [
                    {
                        text: 'User',
                        to: '/user',
                    },
                    {
                        text: 'User_Courses',
                        to: `/courses-of-user?user_id=${user_id}`,
                    },
                    {
                        text: 'Course_Chapters',
                        to: `/chapters-of-course?user_id=${user_id}&course_id=${course_id}`,
                    },
                    {
                        text: 'Form_Records',
                    },
                ];
            },
        },
    },
    {
        path: '/form-record-detail/:id',
        name: 'FormRecordDetail',
        layout: 'dashboard',
        component: () => import('../views/CMS/FormRecordDetail.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/record-of-course/:id',
        name: 'RecordOfCourse',
        layout: 'dashboard',
        component: () => import('../views/CMS/RecordsOfCourse.vue'),
        meta: {
            requiresAuth: true,
            breadCrumb(route) {
                return [
                    {
                        text: 'Course',
                        to: '/course',
                    },
                    {
                        text: 'Course_Records',
                    },
                ];
            },
        },
    },

    // ------------------------ WEB ------------------------
    {
        path: '/my-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/MyPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/search-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/SearchPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/profile-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/ProfilePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/CoursePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/course-detail-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/CourseDetailPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/lesson-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/LessonPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/test-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/TestPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/result-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/ResultPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/result-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/ResultPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/notice-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/NoticePage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/notice-detail-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/NoticeDetailPage.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/form-list-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/FormList.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/form-write-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/FormWrite.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/form-view-page/:id',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/FormView.vue'),
        meta: { requiresAuth: true },
    },
    {
        path: '/faq-page',
        name: 'WEB',
        layout: 'user',
        component: () => import('../views/Web/FaqPage.vue'),
        meta: { requiresAuth: true },
    },
];

// Adding layout property from each route to the meta
// object so it can be accessed later.
function addLayoutToRoute(route, parentLayout = 'default') {
    route.meta = route.meta || {};
    route.meta.layout = route.layout || parentLayout;

    if (route.children) {
        route.children = route.children.map((childRoute) => addLayoutToRoute(childRoute, route.meta.layout));
    }
    return route;
}

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routesMain.map((route) => addLayoutToRoute(route)),
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                selector: to.hash,
                behavior: 'smooth',
            };
        }
        return {
            x: 0,
            y: 0,
            behavior: 'smooth',
        };
    },
});

router.beforeEach(async (to, from, next) => {
    const userInfo = store.getters.getUserInfo;
    // next-line: check if route ("to" object) needs authenticated
    if (to.matched.some((record) => record.meta.requiresAuth) && _.isEmpty(userInfo)) {
        next('/sign-in');
    } else if (!_.isEmpty(userInfo)) {
        const isMaster = checkIsMaster(userInfo);
        const isManager = checkIsManager(userInfo);

        switch (to.name) {
            case 'SignIn':
            // case 'SignUp':
            case 'ForgotPassword':
            case 'ResetPassword': {
                next({ path: from.fullPath });
                break;
            }

            case 'HOME': {
                if (isMaster) {
                    next({ path: '/dashboard' });
                } else if (isManager && !isMobile()) {
                    next({ path: '/dashboard' });
                } else {
                    next({ path: '/my-page' });
                }
                break;
            }

            case 'Dashboard':
            case 'LoginHistory': {
                if (isMaster || isManager) {
                    next();
                } else {
                    next({ path: from.fullPath });
                }
                break;
            }

            // CHECK ROUTE FOR MASTER
            case 'Employee':
            case 'Manager':
            case 'Company':
            case 'Profile':
            case 'FAQCRUD':
            case 'Tutorial': {
                if (isMaster) {
                    next();
                } else {
                    next({ path: from.fullPath });
                }
                break;
            }

            // CHECK ROUTE FOR USER
            case 'WEB': {
                if (isMaster) {
                    next({ path: from.fullPath });
                } else {
                    next();
                }
                break;
            }

            // CHECK ROUTE FOR MANAGER
            default: {
                if (isManager && !isMobile()) {
                    next();
                } else {
                    next({ path: from.fullPath });
                }
                break;
            }
        }
    } else next();
});

export default router;
