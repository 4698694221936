import { HTTP } from '../config/http-common';
import { exportResults, convertParams } from '../utils/index';

export default class API {
    ROUTE = '';
    constructor(route) {
        this.ROUTE = route;
    }

    getList = async (params) =>
        exportResults(
            await HTTP.get(this.ROUTE, {
                params: convertParams(params),
            }),
        );

    findOne = async (id, params) =>
        exportResults(
            await HTTP.get(`${this.ROUTE}/${id}`, {
                params: convertParams(params),
            }),
        );

    deleteOne = async (id) => exportResults(await HTTP.delete(`${this.ROUTE}/${id}`));

    deleteMulti = async (arr) => exportResults(await HTTP.delete(`${this.ROUTE}?items=${arr}`));

    updateOne = async (id, body) => exportResults(await HTTP.put(`${this.ROUTE}/${id}`, body));

    createOne = async (body) => exportResults(await HTTP.post(`${this.ROUTE}`, body));
}
