import { FormQuestion } from '../../../api';

const state = {
    formObject: {},
    formRecordObject: {},
    formQuestionList: [],
    formRecordList: [],
    isLoadingFormQuestion: false,
    formQuestionPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_FORM_OBJECT(state, values) {
        state.formObject = values;
    },
    SET_FORM_RECORD_OBJECT(state, values) {
        state.formRecordObject = values;
    },
    SET_FORM_QUESTION_LIST(state, values) {
        state.formQuestionList = values;
    },
    SET_FORM_RECORD_LIST(state, values) {
        state.formRecordList = values;
    },
    SET_IS_LOADING(state, status) {
        state.isLoadingFormQuestion = status;
    },
    SET_FORM_QUESTION_PAGINATION(state, pagination) {
        state.formQuestionPagination = pagination;
    },
};
const actions = {
    async getFormQuestions({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: ['$all', { form_questions: ['$all', { form_answers: ['$all'] }] }],
                order: [
                    ['form_questions', 'order', 'asc'],
                    ['form_questions', 'form_answers', 'order', 'asc'],
                ],
            };

            const res = await FormQuestion.getFormQuestions(payload.form_id, params);

            commit('SET_FORM_OBJECT', res?.results?.object);
            commit('SET_FORM_QUESTION_LIST', res?.results?.object?.form_questions);
        } catch (error) {
            console.log('🚀 ~ file: index.js:38 ~ getFormQuestionList ~ error:', error);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    // payload: { user_id: string, form_id: string, course_id: string }
    async getFormRecords({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: [
                    'id',
                    'record_id',
                    'form_id',
                    'created_at_unix_timestamp',
                    'updated_at_unix_timestamp',
                    { form: ['id', 'title'] },
                    { record: ['id', 'course_record_id', { course_record: ['id', 'course_id'] }] },
                ],
                where: {
                    user_id: payload?.user_id,
                    form_id: payload?.form_id,
                    '$record.course_record.course_id$': payload?.course_id,
                },
                order: [['created_at_unix_timestamp', 'desc']],
                page: payload?.page || state.formQuestionPagination.page,
                limit: state.formQuestionPagination.pageSize,
            };

            const res = await FormQuestion.getFormAttendCms(params);

            let list = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                list = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.formQuestionPagination.pageSize);
            }

            commit('SET_FORM_RECORD_LIST', list);
            commit('SET_FORM_QUESTION_PAGINATION', {
                ...state.formQuestionPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (error) {
            console.log('🚀 ~ file: index.js:38 ~ getFormQuestionList ~ error:', error);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    async getOneFormRecord({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: [
                    '$all',
                    {
                        form_question_records: ['$all', { form_choice_records: ['$all'] }],
                    },
                ],
                where: { id: payload?.record_id },
                course_id: payload?.course_id,
            };

            const res = await FormQuestion.findOneFormAttend(params);

            commit('SET_FORM_RECORD_OBJECT', res?.results?.objects?.rows?.[0]);
        } catch (error) {
            console.log('🚀 ~ file: index.js:38 ~ getFormQuestionList ~ error:', error);
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },

    async postFormQuestion({ commit }, payload) {
        try {
            const body = { ...payload };

            await FormQuestion.createOne(body);

            return { isSuccess: true, message: 'Create successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async putFormQuestion({ commit }, payload) {
        try {
            const body = { ...payload };

            await FormQuestion.updateOne(payload?.id, body);

            return { isSuccess: true, message: 'Update successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async deleteFormQuestion({ commit }, id) {
        try {
            await FormQuestion.deleteOne(id);
            return { isSuccess: true, message: 'Delete successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
