import md5 from 'md5';
import { Test } from '../../../api';

const state = {
    tests: [],
    isLoadingTest: false,
    testsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    testBody: {
        // test_id: '',
        // title: '',
        // problem_statement: '',
        // explanation: '',
        // multiple_choice: 0,
    },
};
const mutations = {
    SET_TESTS(state, tests) {
        state.tests = tests;
    },
    SET_IS_LOADING_TEST(state, status) {
        state.isLoadingTest = status;
    },
    SET_TESTS_PAGINATION(state, pagination) {
        state.testsPagination = pagination;
    },
    SET_TEST_BODY(state, body) {
        state.testBody = body;
    },
};
const actions = {
    async getTests({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TEST', true);

            let params = {
                fields: ['$all', { test: ['$all'] }, { lesson: ['$all'] }],
                order: [['created_at', 'desc']],
                page: payload?.page || state.testsPagination.page,
                limit: state.testsPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Test.getList(params);

            let tests = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                tests = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.testsPagination.pageSize);
            }

            commit('SET_TESTS', tests);
            commit('SET_TESTS_PAGINATION', {
                ...state.testsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_TEST', false);
        }
    },
    async postNewTest({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TEST', true);

            const testBody = {
                ...payload,
                title: payload?.title?.trim() || '',
                // problem_statement: payload?.problem_statement || '',
                // explanation: payload?.explanation || '',
            };

            const res = await Test.createOne(testBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_TEST', false);
        }
    },
    async putOneTest({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TEST', true);
            const id = payload.id;
            const testBody = {
                ...payload.body,
                // title: payload?.title?.trim() || '',
                // problem_statement: payload?.problem_statement || '',
                // explanation: payload?.explanation || '',
            };

            const res = await Test.updateOne(id, testBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_TEST', false);
        }
    },
    async deleteOneTest({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_TEST', true);
            const res = await Test.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_TEST', false);
        }
    },
    async deleteMultiSubTests({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_TEST', true);
            const res = await Test.deleteMulti(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_TEST', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
