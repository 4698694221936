<template lang="">
    <form @submit.prevent="onSubmit" class="search">
        <a-input type="text" :placeholder="$t('header.input_search_placeholder')" v-model="keyword" />
        <button type="submit">
            <v-icon class="fa-solid fa-magnifying-glass"></v-icon>
        </button>
    </form>
</template>

<script>
export default {
    data() {
        return {
            keyword: this.$route.query.keyword || '',
        };
    },
    watch: {
        '$route.fullPath': function () {
            if (this.$route.path !== '/search-page') {
                this.keyword = '';
            }
        },
    },
    methods: {
        onSubmit() {
            this.$router.push({
                path: '/search-page',
                query: { keyword: this.keyword },
            });
        },
    },
};
</script>

<style scoped lang="scss">
.search {
    position: relative;
    & input {
        width: 400px;
        padding-left: 50px;
        border-radius: 30px;
        border-color: #000;
        &:hover,
        &:focus {
            border-color: #000;
        }
    }
    & button {
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    & button i {
        font-size: 18px;
    }
}

@media screen and (max-width: 1300px) {
    .search {
        & input {
            width: 300px;
            padding-left: 40px;
        }
    }
}

@media screen and (max-width: 767px) {
    .search {
        & input {
            width: 100%;
        }
    }
}
</style>
