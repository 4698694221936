import { Tutorial } from '../../../api';

const state = {
    tutorialObject: {},
    tutorialList: [],
    isLoadingTutorial: false,
    tutorialPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_TUTORIAL_OBJECT(state, values) {
        state.tutorialObject = values;
    },
    SET_TUTORIAL_LIST(state, values) {
        state.tutorialList = values;
    },
    SET_IS_LOADING_TUTORIAL(state, status) {
        state.isLoadingTutorial = status;
    },
    SET_TUTORIAL_PAGINATION(state, pagination) {
        state.tutorialPagination = pagination;
    },
};
const actions = {
    async getTutorialList({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TUTORIAL', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.tutorialPagination.page,
                limit: state.tutorialPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [`${payload?.field}`]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Tutorial.getList(params);

            let list = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                list = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.tutorialPagination.pageSize);
            }

            commit('SET_TUTORIAL_LIST', list);
            commit('SET_TUTORIAL_PAGINATION', {
                ...state.tutorialPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING_TUTORIAL', false);
        }
    },

    async getOneTutorial({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TUTORIAL', true);

            let params = {
                fields: ['title', 'content', { tutorial_details: ['title', 'content', 'step'] }],
                order: [['tutorial_details', 'step', 'asc']],
            };

            const res = await Tutorial.findOne(payload.id, params);
            console.log('🚀 ~ file: index.js:79 ~ getOneTutorial ~ res:', res);

            commit('SET_TUTORIAL_OBJECT', res?.results?.object);
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING_TUTORIAL', false);
        }
    },

    async postTutorial({ commit }, payload) {
        try {
            const body = { ...payload };

            await Tutorial.createOne(body);

            return { isSuccess: true, message: 'Create successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async putTutorial({ commit }, payload) {
        try {
            const body = { ...payload };

            await Tutorial.updateOne(payload?.id, body);

            return { isSuccess: true, message: 'Update successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async deleteTutorial({ commit }, id) {
        try {
            await Tutorial.deleteOne(id);
            return { isSuccess: true, message: 'Delete successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
