<template lang="">
    <div class="loading" v-bind:style="{ padding: pad ? `${pad}px` : '50px' }">
        <EllipsisLoader color="#656ef1" />
    </div>
</template>

<script>
import { EllipsisLoader } from 'vue-spinners-css';

export default {
    props: {
        pad: {
            type: Number,
        },
    },
    components: {
        EllipsisLoader,
    },
};
</script>

<style lang="scss">
.loading {
    padding: 50px 0;
    text-align: center;
}
</style>
