import Vue from 'vue';
import Vuex from 'vuex';
import AuthModule from './auth/module';
import LangModule from './lang/module';

// CMS
import UserModule from './cms/User';
import TestModule from './cms/Test';
import ExcelModule from './cms/Excel';
import CourseModule from './cms/Course';
import LessonModule from './cms/Lesson';
import CompanyModule from './cms/Company';
import ManagerModule from './cms/Manager';
import ChapterModule from './cms/Chapter';
import SectionModule from './cms/Section';
import EmployeeModule from './cms/Employee';
import QuestionModule from './cms/Question';
import DashboardModule from './cms/Dashboard';
import UserFolderModule from './cms/UserFolder';
import FolderItemModule from './cms/FolderItem';
import CourseFolderModule from './cms/CourseFolder';
import LoginHistoryModule from './cms/LoginHistory';
import NotificationModuleCMS from './cms/Notification';
import CoursesOfUserModule from './cms/CoursesOfUser';
import ResultsSectionModule from './cms/ResultsSection';
import ChaptersOfCourseModule from './cms/ChaptersOfCourse';
import FaqModule from './cms/FAQ';
import FormQuestionModule from './cms/FormQuestion';
import TutorialModule from './cms/Tutorial';
import TutorialDetailModule from './cms/TutorialDetail';
import RecordOfCourseModule from './cms/RecordOfCourse';

// WEB
import TestWebModule from './web/test';
import RecordWebModule from './web/record';
import NotificationModule from './web/notification';

Vue.use(Vuex);

// Create a new store instance.
const store = new Vuex.Store({
    modules: {
        auth: AuthModule,
        lang: LangModule,

        // CMS
        user: UserModule,
        test: TestModule,
        excel: ExcelModule,
        course: CourseModule,
        lesson: LessonModule,
        company: CompanyModule,
        manager: ManagerModule,
        chapter: ChapterModule,
        section: SectionModule,
        employee: EmployeeModule,
        question: QuestionModule,
        dashboard: DashboardModule,
        userFolder: UserFolderModule,
        courseFolder: CourseFolderModule,
        folderItem: FolderItemModule,
        loginHistory: LoginHistoryModule,
        notification: NotificationModuleCMS,
        coursesOfUser: CoursesOfUserModule,
        resultsSection: ResultsSectionModule,
        chaptersOfCourse: ChaptersOfCourseModule,
        faq: FaqModule,
        formQuestion: FormQuestionModule,
        tutorial: TutorialModule,
        tutorialDetail: TutorialDetailModule,
        recordCourse: RecordOfCourseModule,

        // WEB
        testWeb: TestWebModule,
        recordWeb: RecordWebModule,
        notificationWeb: NotificationModule,
    },
});

export default store;
