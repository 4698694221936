const mixinLoadings = {
    data() {
        return {
            loader: null,
        };
    },
    methods: {
        showLoading() {
            this.loader = this.$loading.show({
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                color: '#1890FF',
                loader: 'dots',
                blur: '0',
            });
        },
        hideLoading() {
            this.loader.hide();
        },
    },
};

export default mixinLoadings;
