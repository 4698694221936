import * as TestAPI from '../../../api/testAPI';

const state = {
    step: 'READY', // READY or STARTED
    testObject: {},
    userAnswers: [], // [{ question_id: string, answer_id: ["string"], multiple_choice: number }]
};

const mutations = {
    SET_STEP(state, string) {
        state.step = string;
    },
    SET_TEST_OBJECT(state, object) {
        state.testObject = object;
    },
    SET_USER_ANSWERS(state, array) {
        state.userAnswers = array;
    },
    RESET_STATE(state) {
        state.step = 'READY';
        state.testObject = {};
        state.userAnswers = [];
    },
};

const actions = {
    // payload: { id: string, onSuccess: (boolean, data) => { } }
    async getTest({ commit, state }, payload) {
        try {
            const res = await TestAPI.findOne(payload.id, {
                fields: [
                    'title',
                    'time_limit',
                    'pass_score',
                    {
                        test_questions: [
                            'id',
                            'title',
                            'problem_statement',
                            'explanation',
                            'order',
                            'multiple_choice',
                            { test_answers: ['id', 'order', 'description', 'explanation', 'test_question_id'] },
                        ],
                    },
                ],
                order: [
                    ['test_questions', 'order', 'asc'],
                    ['test_questions', 'test_answers', 'order', 'asc'],
                ],
            });
            commit('SET_TEST_OBJECT', res?.results?.object);
            payload.onSuccess(true);
        } catch (error) {
            payload.onSuccess(false, error);
        }
    },

    // payload: { course_id: string, time: number, onSuccess: (boolean, data) => { } }
    async attemptTest({ commit, state }, payload) {
        try {
            const res = await TestAPI.attemptTest({
                course_id: payload.course_id,
                time: payload.time,
                test_id: state.testObject.id,
                answers: state.userAnswers.map((answer) => ({
                    question_id: answer.question_id,
                    answer_id: answer.answer_id,
                })),
            });
            payload.onSuccess(true, res?.results?.object);
        } catch (error) {
            payload.onSuccess(false, error);
        }
    },

    // payload: { question_id: string, answer_id: ["string"], multiple_choice: number }
    addUserAnswer({ commit, state }, payload) {
        const newUserAnswers = [...state.userAnswers];
        const indexAnswer = newUserAnswers.findIndex((answer) => answer.question_id === payload.question_id);

        if (indexAnswer !== -1) {
            if (payload.multiple_choice !== 1 && payload.answer_id.length === 0) {
                newUserAnswers.splice(indexAnswer, 1);
            } else {
                newUserAnswers[indexAnswer].answer_id = payload.answer_id;
            }
        } else {
            newUserAnswers.push(payload);
        }

        commit('SET_USER_ANSWERS', newUserAnswers);
    },

    setStep({ commit, state }, payload) {
        commit('SET_STEP', payload);
    },

    resetState({ commit, state }, payload) {
        commit('RESET_STATE');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
