import md5 from 'md5';
import { ResultsSection } from '../../../api';

const state = {
    resultsSections: [],
    isLoadingResultsSection: false,
    resultsSectionsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    resultsSectionBody: {
        // ResultsSection_id: '',
        // title: '',
        // problem_statement: '',
        // explanation: '',
        // multiple_choice: 0,
    },
};
const mutations = {
    SET_RESULTS_SECTION(state, resultsSections) {
        state.resultsSections = resultsSections;
    },
    SET_IS_LOADING_RESULTS_SECTION(state, status) {
        state.isLoadingResultsSection = status;
    },
    SET_RESULTS_SECTION_PAGINATION(state, pagination) {
        state.resultsSectionsPagination = pagination;
    },
    SET_RESULT_SECTION_BODY(state, body) {
        state.resultsSectionBody = body;
    },
};
const actions = {
    async getResultsSection({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_RESULTS_SECTION', true);

            let params = {
                fields: ['$all', { record: ['$all', { course_record: ['$all'] }] }],
                where: {
                    user_id: payload?.user_id,
                    test_id: payload?.test_id,
                    '$record.course_record.course_id$': payload?.course_id,
                },
                order: [['created_at', 'desc']],
                page: payload?.page || state.resultsSectionsPagination.page,
                limit: state.resultsSectionsPagination.pageSize,
            };

            const res = await ResultsSection.getList(params);

            let resultsSections = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                resultsSections = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.resultsSectionsPagination.pageSize);
            }

            commit('SET_RESULTS_SECTION', resultsSections);
            commit('SET_RESULTS_SECTION_PAGINATION', {
                ...state.resultsSectionsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_RESULTS_SECTION', false);
        }
    },
    async postNewResultsSection({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_RESULTS_SECTION', true);

            const ResultsSectionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
                // problem_statement: payload?.problem_statement || '',
                // explanation: payload?.explanation || '',
            };

            const res = await ResultsSection.createOne(ResultsSectionBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_RESULTS_SECTION', false);
        }
    },
    async putOneResultsSection({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_RESULTS_SECTION', true);
            const id = payload.id;
            const ResultsSectionBody = {
                ...payload.body,
                // title: payload?.title?.trim() || '',
                // problem_statement: payload?.problem_statement || '',
                // explanation: payload?.explanation || '',
            };

            const res = await ResultsSection.updateOne(id, ResultsSectionBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_RESULTS_SECTION', false);
        }
    },
    async deleteOneResultsSection({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_RESULTS_SECTION', true);
            const res = await ResultsSection.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_RESULTS_SECTION', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
