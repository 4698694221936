import md5 from 'md5';
import * as cmsSectionAPI from '../../../api/cmsSectionAPI';

const state = {
    sections: [],
    isLoadingSection: false,
    sectionsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    sectionBody: {
        title: '',
        text: '',
        problem_statement: '',
        explanation: '',
    },
    lessonBody: {
        title: '',
        text: '',
    },
    testBody: {
        title: '',
        problem_statement: '',
        explanation: '',
        time_list: 1,
    },
};
const mutations = {
    SET_SECTIONS(state, sections) {
        state.sections = sections;
    },
    SET_IS_LOADING_SECTION(state, status) {
        state.isLoadingSection = status;
    },
    SET_SECTIONS_PAGINATION(state, pagination) {
        state.sectionsPagination = pagination;
    },
    SET_SECTION_BODY(state, body) {
        state.sectionBody = body;
    },
};
const actions = {
    async getSections({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);

            let params = {
                fields: ['$all', { test: ['$all'] }, { lesson: ['$all'] }, { form: ['$all'] }],
                order: [['order', 'desc']],
                page: payload?.page || state.sectionsPagination.page,
                limit: state.sectionsPagination.pageSize,
            };

            if (payload?.chapter_id || (payload?.field && payload?.search)) {
                params.where = [
                    { chapter_id: payload?.chapter_id },
                    {
                        $or: [
                            { '$lesson.title$': { $iLike: `%${payload?.search}%` } },
                            { '$test.title$': { $iLike: `%${payload?.search}%` } },
                            { '$form.title$': { $iLike: `%${payload?.search}%` } },
                        ],
                    },
                ];
            }

            const res = await cmsSectionAPI.getList(params);

            let sections = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                sections = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.sectionsPagination.pageSize);
            }

            commit('SET_SECTIONS', sections);
            commit('SET_SECTIONS_PAGINATION', {
                ...state.sectionsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async postNewLesson({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);

            const lessonBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.createOneLesson(lessonBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async postNewTest({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);

            const testBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.createOneTest(testBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async postNewForm({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);

            const formBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.createOneForm(formBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async putOneLesson({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const id = payload.id;
            const sectionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.updateOneLesson(id, sectionBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async putOneTest({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const id = payload.id;
            const sectionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.updateOneTest(id, sectionBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async putOneForm({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const id = payload.id;
            const sectionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await cmsSectionAPI.updateOneForm(id, sectionBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async deleteOneLesson({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const res = await cmsSectionAPI.deleteOneLesson(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async deleteOneTest({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const res = await cmsSectionAPI.deleteOneTest(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async deleteOneForm({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const res = await cmsSectionAPI.deleteOneForm(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
    async deleteMultiSections({ commit, state }, arr) {
        try {
            commit('SET_IS_LOADING_SECTION', true);
            const res = await cmsSectionAPI.deleteMultiSections(arr);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_SECTION', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
