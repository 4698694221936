import * as TYPE from './types';
import app from '../../main';

const moduleLang = {
    state: () => ({
        language: 'jpn',
    }),

    getters: {
        getLang(state) {
            return state.language;
        },
    },

    mutations: {
        [TYPE.SET_LANG](state, payload) {
            app.$i18n.locale = payload;
            state.language = payload;
        },
    },

    actions: {
        setLang(context, data) {
            context.commit(TYPE.SET_LANG, data);
        },
    },
};

export default moduleLang;
