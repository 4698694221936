import API from './api';
import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export default class FormQuestion extends API {
    getForm = async (form_id, params) =>
        exportResults(
            await HTTP.get(`${ROUTE_API.FORM}/${form_id}`, {
                params: convertParams(params),
            }),
        );

    getFormQuestions = async (form_id, params) =>
        exportResults(
            await HTTP.get(`${ROUTE_API.FORM}/${form_id}`, {
                params: convertParams(params),
            }),
        );

    attemptForm = async (body) => exportResults(await HTTP.post(ROUTE_API.ATTEMPT_FORM, body));

    getFormAttend = async (params) =>
        exportResults(
            await HTTP.get(ROUTE_API.FORM_ATTEND_WEB, {
                params: convertParams(params),
            }),
        );

    findOneFormAttend = async (params) =>
        exportResults(
            await HTTP.get(ROUTE_API.FORM_ATTEND_WEB, {
                params: convertParams(params),
            }),
        );

    getFormAttendCms = async (params) =>
        exportResults(
            await HTTP.get(ROUTE_API.FORM_ATTEND, {
                params: convertParams(params),
            }),
        );
}
