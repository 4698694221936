import md5 from 'md5';
import { UserFolder, CourseFolder, FolderItem, User, Course } from '../../../api';

const state = {
    folderItems: [],
    isLoadingFolderItem: false,
    folderItemsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    folderItemBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: null,
        role: '',
    },
};
const mutations = {
    SET_FOLDER_ITEMS(state, folderItems) {
        state.folderItems = folderItems;
    },
    SET_IS_LOADING_FOLDER_ITEM(state, status) {
        state.isLoadingFolderItem = status;
    },
    SET_FOLDER_ITEMS_PAGINATION(state, pagination) {
        state.folderItemsPagination = pagination;
    },
    SET_FOLDER_ITEM_BODY(state, body) {
        state.folderItemBody = body;
    },
};
const actions = {
    // payload: { page: numbder, field: string, search: string, folder_id: string, type: string }
    async getItemsFromFolder({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_FOLDER_ITEM', true);

            let params = {
                order: [['created_at_unix_timestamp', 'desc']],
                page: payload?.page || state.folderItemsPagination.page,
                limit: payload?.limit || state.folderItemsPagination.pageSize,
            };

            // type: USER / COURSE
            if (payload?.type === 'USER') {
                params = {
                    ...params,
                    fields: ['$all', { user: ['$all', '$paranoid'] }],
                    where: { folder_id: payload?.folder_id, course_id: { $is: null } },
                };

                if (payload?.field && payload?.search) {
                    params.where = {
                        ...params.where,
                        [`$user.${payload?.field}$`]: { $iLike: `%${payload?.search}%` },
                    };
                }
            }

            if (payload?.type === 'COURSE') {
                params = {
                    ...params,
                    fields: ['$all', { course: ['$all'] }],
                    where: { folder_id: payload?.folder_id, user_id: { $is: null } },
                };

                if (payload?.field && payload?.search) {
                    params.where = {
                        ...params.where,
                        [`$course.${payload?.field}$`]: { $iLike: `%${payload?.search}%` },
                    };
                }
            }

            const res = await FolderItem.getList(params);

            let list = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                if (payload?.type === 'USER') {
                    list = res?.results?.objects?.rows?.map((item) => ({ ...item?.user, item_folder_id: item?.id }));
                }
                if (payload?.type === 'COURSE') {
                    list = res?.results?.objects?.rows?.map((item) => ({ ...item?.course, item_folder_id: item?.id }));
                }
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.folderItemsPagination.pageSize);
            }

            commit('SET_FOLDER_ITEMS', list);
            commit('SET_FOLDER_ITEMS_PAGINATION', {
                ...state.folderItemsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });

            return { isSuccess: true, mess: 'Get List Success!' };
        } catch (error) {
            return { isSuccess: false, mess: error };
        } finally {
            commit('SET_IS_LOADING_FOLDER_ITEM', false);
        }
    },

    // payload: { folder_id: string, type: string, items: [ string ] }
    async createMultiItem({ commit, state }, payload) {
        try {
            // type: USER / COURSE
            if (payload?.type === 'USER') {
                await UserFolder.createMultiUser(payload);
            }

            if (payload?.type === 'COURSE') {
                await CourseFolder.createMultiCourse(payload);
            }

            return { isSuccess: true, mess: 'Create Success!' };
        } catch (error) {
            return { isSuccess: false, mess: error };
        }
    },

    async putItem({ commit, state }, payload) {
        try {
            let res = {};

            if (payload?.type === 'USER') {
                const body = {
                    avatar: payload?.avatar || '',
                    full_name: payload?.full_name?.trim() || '',
                    email: payload?.email?.trim() || '',
                    gender: payload?.gender || null,
                    role: payload?.role || '',
                };
                res = await User.updateOne(payload?.id, body);
            }

            if (payload?.type === 'COURSE') {
                const body = {
                    title: payload?.title?.trim() || '',
                    thumbnail: payload?.thumbnail || '',
                    note: payload?.note || '',
                    description: payload?.description || '',
                    array_hashtag: payload?.array_hashtag || [],
                    course_settings: payload?.course_settings || [],
                    materials: payload?.materials || [],
                    status: payload?.status || false,
                };
                res = await Course.updateOne(payload?.id, body);
            }

            return { isSuccess: true, mess: 'Update Success!', data: res?.results?.object };
        } catch (error) {
            return { isSuccess: false, mess: error };
        }
    },

    // payload: string
    async deleteItem({ commit, state }, payload) {
        try {
            await FolderItem.deleteOne(payload);
            return { isSuccess: true, mess: 'Delete Success!' };
        } catch (error) {
            return { isSuccess: false, mess: error };
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
