import md5 from 'md5';
import { Chapter } from '../../../api';

const state = {
    chapters: [],
    isLoadingChapter: false,
    chaptersPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    chapterBody: {
        title: '',
    },
};
const mutations = {
    SET_CHAPTERS(state, chapters) {
        state.chapters = chapters;
    },
    SET_IS_LOADING_CHAPTER(state, status) {
        state.isLoadingChapter = status;
    },
    SET_CHAPTERS_PAGINATION(state, pagination) {
        state.chaptersPagination = pagination;
    },
    SET_CHAPTER_BODY(state, body) {
        state.chapterBody = body;
    },
};
const actions = {
    async getChaptersSections({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);

            let params = {
                fields: ['$all', { sections: ['$all', { test: ['$all'] }, { lesson: ['$all'] }, { form: ['$all'] }] }],
                order: [['created_at', 'desc']],
                page: payload?.page || state.chaptersPagination.page,
                limit: payload?.limit || state.chaptersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Chapter.getList(params);

            let chapters = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                chapters = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.chaptersPagination.pageSize);
            }

            commit('SET_CHAPTERS', chapters);
            commit('SET_CHAPTERS_PAGINATION', {
                ...state.chaptersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async getChapters({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.chaptersPagination.page,
                limit: state.chaptersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await Chapter.getList(params);

            let chapters = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                chapters = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.chaptersPagination.pageSize);
            }

            commit('SET_CHAPTERS', chapters);
            commit('SET_CHAPTERS_PAGINATION', {
                ...state.chaptersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async postNewChapter({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);

            const chapterBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await Chapter.createOne(chapterBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async putOneChapter({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);
            const id = payload.id;
            const chapterBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await Chapter.updateOne(id, chapterBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async deleteOneChapter({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);
            const res = await Chapter.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async deleteMultiChapters({ commit, state }, arr) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);
            const res = await Chapter.deleteMulti(arr);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
    async duplicateChapter({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_CHAPTER', true);
            const res = await Chapter.duplicateChapter({ chapter_id: id });
            return { isSuccess: true, mess: 'Duplicated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: true, mess: 'Duplicated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_CHAPTER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
