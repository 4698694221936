import { UserFolder } from '../../../api';

const state = {
    userFolders: [],
    isLoadingUserFolder: false,
    userFoldersPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    userBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: null,
        role: '',
    },
};
const mutations = {
    SET_USER_FOLDERS(state, userFolders) {
        state.userFolders = userFolders;
    },
    SET_IS_LOADING_USER_FOLDER(state, status) {
        state.isLoadingUserFolder = status;
    },
    SET_USER_FOLDER_PAGINATION(state, pagination) {
        state.userFoldersPagination = pagination;
    },
    SET_USER_FOLDER_BODY(state, body) {
        state.userBody = body;
    },
};
const actions = {
    // payload: { folder_id: string, page: numbder, field: string, search: string }
    async getUserFolders({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER_FOLDER', true);

            let params = {
                fields: ['$all'],
                where: { type: 'USER', parent_id: payload?.folder_id },
                order: [['created_at', 'desc']],
                page: payload?.page || state.userFoldersPagination.page,
                limit: payload?.limit || state.userFoldersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    ...params.where,
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            let res = await UserFolder.getList(params);

            let userFolders = [];
            let totalItems = 0;
            let totalPages = 0;

            if (res?.results?.objects?.rows) {
                userFolders = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.userFoldersPagination.pageSize);
            }

            commit('SET_USER_FOLDERS', userFolders);
            commit('SET_USER_FOLDER_PAGINATION', {
                ...state.userFoldersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING_USER_FOLDER', false);
        }
    },
    async postNewUserFolder({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER_FOLDER', true);

            const userBody = {
                ...payload,
                type: 'USER',
                title: payload?.title?.trim() || '',
            };

            const res = await UserFolder.createOne(userBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER_FOLDER', false);
        }
    },
    async putOneUserFolder({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER_FOLDER', true);
            const id = payload.id;
            const userBody = {
                type: 'USER',
                title: payload?.title?.trim() || '',
            };

            const res = await UserFolder.updateOne(id, userBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER_FOLDER', false);
        }
    },
    async deleteOneUserFolder({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_USER_FOLDER', true);
            const res = await UserFolder.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER_FOLDER', false);
        }
    },
    async postCreateMultiItem({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER_FOLDER', true);

            const userBody = {
                ...payload,
                folder_id: payload.folder_id || '',
                type: 'USER',
                items: payload?.items,
            };

            const res = await UserFolder.createMultiUser(userBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER_FOLDER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
