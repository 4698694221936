import { ALERT, CRUD } from '../constants';

const mixinToasts = {
    methods: {
        showToast(type, message) {
            this.$toast.open({
                type,
                message,
                position: 'top-right',
                duration: 3000,
            });
        },

        showSuccess(type) {
            let message = '';

            switch (type) {
                case CRUD.CREATE: {
                    message = this.$t(`alert.${ALERT.CREATE_SUCCESS}`);
                    break;
                }
                case CRUD.UPDATE: {
                    message = this.$t(`alert.${ALERT.UPDATE_SUCCESS}`);
                    break;
                }
                case CRUD.DELETE: {
                    message = this.$t(`alert.${ALERT.DELETE_SUCCESS}`);
                    break;
                }
                case CRUD.PUSH_NOTICE: {
                    message = this.$t(`alert.${ALERT.PUSH_NOTICE_SUCCESS}`);
                    break;
                }
                default:
                    break;
            }

            this.$toast.open({
                message,
                type: 'success',
                position: 'top-right',
                duration: 2000,
            });
        },

        showError(errData) {
            const currentLanguage = this.$store.getters.getLang;
            let message =
                errData?.response?.data?.message?.[currentLanguage === 'jpn' ? 'jp' : 'en'] ||
                errData?.response?.data?.message ||
                'Error';

            this.$toast.open({
                type: 'error',
                message,
                position: 'top-right',
                duration: 3000,
            });
        },
    },
};

export default mixinToasts;
