import md5 from 'md5';
import { Dashboard } from '../../../api';

const state = {
    userObj: {},
    loginHistoryObj: {},
    isLoadingUser: false,
};
const mutations = {
    SET_USER_OBJ(state, users) {
        state.userObj = users;
    },
    SET_LOGIN_HISTORY_OBJ(state, loginData) {
        state.loginHistoryObj = loginData;
    },
    SET_IS_LOADING_USER(state, status) {
        state.isLoadingUser = status;
    },
};
const actions = {
    async getDashboard({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER', true);

            const res = await Dashboard.getList(payload);

            const userObj = res.results.object[0].user;
            const loginHistoryObj = res.results.object[0].loginHistory;

            commit('SET_USER_OBJ', userObj);
            commit('SET_LOGIN_HISTORY_OBJ', loginHistoryObj);
            return { userObj, loginHistoryObj };
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_USER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
