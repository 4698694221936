import md5 from 'md5';
import { cmsUploadExcelApi } from '../../../api';

const state = {
    isLoading: false,
};
const mutations = {
    SET_Excel(state, tests) {
        state.tests = tests;
    },
    SET_IS_LOADING(state, status) {
        state.isLoading = status;
    },
};
const actions = {
    async getDownUser({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            const res = await cmsUploadExcelApi.getDownloadUser();
            if (res.code === 200) {
                let fileLink = document.createElement('a');
                fileLink.href = res.results.object.url;
                fileLink.setAttribute('download', 'file.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
    async getDownUserSample({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            const res = await cmsUploadExcelApi.getDownloadUsersample();
            if (res.code === 200) {
                let fileLink = document.createElement('a');
                fileLink.href = res.results.object.url;
                fileLink.setAttribute('download', 'file.xlsx');
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
