import Vue from 'vue';
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import debug from './cores/debug';
import * as VueSpinnersCss from 'vue-spinners-css';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
// import 'vue-datetime/dist/vue-datetime.css';

import './scss/app.scss';
import router from './router';
import store from './store';
import i18n from './lang/i18n';

import CKEditor from '@ckeditor/ckeditor5-vue2';
import DefaultLayout from './layouts/CMS/Default.vue';
import DashboardLayout from './layouts/CMS/Dashboard.vue';
import DashboardRTLLayout from './layouts/CMS/DashboardRTL.vue';
import LayoutUser from './layouts/Web/LayoutUser.vue';
import FPagination from './components/CMS/pagination/FPagination.vue';
import FNotificationDialog from './components/CMS/Dialog/NotificationDialog.vue';
// import { Datetime } from 'vue-datetime';

Vue.use(Antd);
Vue.use(vuetify);
Vue.use(Loading);
Vue.use(VueToast);
Vue.use(VueSpinnersCss);
Vue.use(CKEditor);
// Vue.use(Datetime);

Vue.config.productionTip = false;

Vue.component('layout-default', DefaultLayout);
Vue.component('layout-dashboard', DashboardLayout);
Vue.component('layout-dashboard-rtl', DashboardRTLLayout);
Vue.component('layout-user', LayoutUser);
Vue.component('f-pagination', FPagination);
Vue.component('f-notification-dialog', FNotificationDialog);
// Vue.component('datetime', Datetime);

const app = new Vue({
    i18n,
    router,
    store,
    vuetify,
    debug,
    render: (h) => h(App),
}).$mount('#app');

export default app;
