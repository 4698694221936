import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const findOne = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.RECORD}/${id}`, {
            params: convertParams(params),
        }),
    );

export const getTestRecord = async (id, params) =>
    exportResults(
        await HTTP.get(`${ROUTE_API.TEST_RECORD}/${id}`, {
            params: convertParams(params),
        }),
    );

export const getTestRecordCMS = async (payload) => exportResults(await HTTP.post(ROUTE_API.TEST_RECORD_CMS, payload));

export const getTestRecordUser = async (payload) => exportResults(await HTTP.post(ROUTE_API.TEST_RECORD_USER, payload));
