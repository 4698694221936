var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-layout-sider',{staticClass:"sider-primary",class:['ant-layout-sider-' + _vm.sidebarColor, 'ant-layout-sider-' + _vm.sidebarTheme],style:({ backgroundColor: 'transparent' }),attrs:{"collapsible":"","breakpoint":"lg","collapsed-width":"0","width":"250px","collapsed":_vm.sidebarCollapsed,"trigger":null,"theme":"light"},on:{"collapse":function($event){return _vm.$emit('toggleSidebar', !_vm.sidebarCollapsed)}}},[_c('router-link',{attrs:{"to":"/dashboard"}},[_c('div',{staticClass:"brand"},[_c('img',{style:({ height: 'auto', marginRight: 0 }),attrs:{"src":require("../../../assets/images/logo.jpg"),"alt":"logo","width":"100%"}})])]),_c('hr'),_c('a-menu',{attrs:{"theme":"light","mode":"inline"}},[_c('a-menu-item',{key:"1"},[_c('v-card',{staticClass:"mx-auto sidebar__menu",attrs:{"width":"300"}},[(_vm.isMaster)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedItemMaster),callback:function ($$v) {_vm.selectedItemMaster=$$v},expression:"selectedItemMaster"}},_vm._l((_vm.master),function(ref,index){
var title = ref[0];
var icon = ref[1];
var url = ref[2];
return _c('router-link',{key:index,attrs:{"to":url}},[_c('v-list-item',{key:index},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""},domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1)],1)}),1)],1):_vm._e(),_c('v-divider'),(_vm.isManager)?_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-list-item-group',{attrs:{"mandatory":""},model:{value:(_vm.selectedItemManager),callback:function ($$v) {_vm.selectedItemManager=$$v},expression:"selectedItemManager"}},_vm._l((_vm.cms),function(ref,index){
var title = ref[0];
var icon = ref[1];
var url = ref[2];
var child = ref[3];
return (child.length === 0)?_c('v-list-item',{key:index,attrs:{"to":child.length === 0 ? url : '',"link":""}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""},domProps:{"textContent":_vm._s(icon)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(title)}})],1):_c('v-list-group',{scopedSlots:_vm._u([{key:"prependIcon",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',{attrs:{"dense":""},domProps:{"textContent":_vm._s(icon)}})],1)]},proxy:true},{key:"activator",fn:function(){return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(title))])],1)]},proxy:true}],null,false,3743629807)},_vm._l((child),function(ref,i){
var titleChild = ref[0];
var iconChild = ref[1];
var urlChild = ref[2];
return _c('v-list-item',{key:i,staticClass:"ml-5",attrs:{"to":urlChild,"link":""}},[_c('v-list-item-icon',[_c('v-icon',{domProps:{"textContent":_vm._s(iconChild)}})],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(titleChild)}})],1)}),1)}),1)],1):_vm._e()],1)],1)],1),_c('div',{staticClass:"btn-group"},[_c('a-button',{staticClass:"aside-logout",attrs:{"type":"danger"},on:{"click":_vm.onLogout}},[_c('span',{staticClass:"font-bold text-light"},[_vm._v(_vm._s(_vm.$t('log_out')))])])],1),_c('f-notification-dialog',{staticClass:"dialogNoti",attrs:{"isShowDialog":_vm.isShowDialogLogOut,"width":"500px","title":_vm.$t('log_out'),"content":_vm.$t('confirm_log_out'),"actions":_vm.actionsLogout}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }