import * as RecordAPI from '../../../api/recordAPI';

const state = {
    recordObject: {},
};

const mutations = {
    SET_RECORD_OBJECT(state, object) {
        state.recordObject = object;
    },
    RESET_STATE(state) {},
};

const actions = {
    // payload: { params: { }, onSuccess: (boolean, msgErr) => { } }
    async getRecord({ commit, state }, payload) {
        const { params, onSuccess } = payload;
        try {
            const res = await RecordAPI.getTestRecord(params.id, {});
            commit('SET_RECORD_OBJECT', res?.results?.object);
            onSuccess(true);
        } catch (error) {
            onSuccess(false, error);
        }
    },

    // payload: { test_id: string, test_attend_id: string, onSuccess: (boolean, msgErr) => { } }
    async getRecordCMS({ commit, state }, payload) {
        const { params, onSuccess } = payload;
        try {
            const res = await RecordAPI.getTestRecordCMS({
                test_id: payload?.test_id,
                test_attend_id: payload?.test_attend_id,
            });
            commit('SET_RECORD_OBJECT', res?.results?.object?.res?.[0]);
            onSuccess(true);
        } catch (error) {
            onSuccess(false, error);
        }
    },

    async getRecordUser({ commit, state }, payload) {
        const { params, onSuccess } = payload;
        try {
            const res = await RecordAPI.getTestRecordUser({
                test_id: payload?.test_id,
                test_attend_id: payload?.test_attend_id,
            });
            commit('SET_RECORD_OBJECT', res?.results?.object?.res?.[0]);
            onSuccess(true);
        } catch (error) {
            onSuccess(false, error);
        }
    },

    resetState({ commit, state }, payload) {
        commit('RESET_STATE');
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
