import md5 from 'md5';
import { FolderItem, CourseFolder } from '../../../api';

const state = {
    courseFolders: [],
    isLoadingCourse: false,
    courseFoldersPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    courseBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: null,
        role: '',
    },
};
const mutations = {
    SET_COURSE_FOLDERS(state, courseFolders) {
        state.courseFolders = courseFolders;
    },
    SET_IS_LOADING_COURSE_FOLDER(state, status) {
        state.isLoadingCourse = status;
    },
    SET_COURSE_FOLDER_PAGINATION(state, pagination) {
        state.courseFoldersPagination = pagination;
    },
    SET_COURSE_FOLDER_BODY(state, body) {
        state.courseBody = body;
    },
};
const actions = {
    async getCourseFolders({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE_FOLDER', true);

            let params = {
                fields: ['$all'],
                where: { type: 'COURSE', parent_id: payload?.folder_id },
                order: [['created_at', 'desc']],
                page: payload?.page || state.courseFoldersPagination.page,
                limit: payload?.limit || state.courseFoldersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            let res = await CourseFolder.getList(params);

            let courseFolders = [];
            let totalItems = 0;
            let totalPages = 0;

            if (res?.results?.objects?.rows) {
                courseFolders = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.courseFoldersPagination.pageSize);
            }

            commit('SET_COURSE_FOLDERS', courseFolders);
            commit('SET_COURSE_FOLDER_PAGINATION', {
                ...state.courseFoldersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING_COURSE_FOLDER', false);
        }
    },
    async postNewCourseFolder({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE_FOLDER', true);

            const courseBody = {
                ...payload,
                type: 'COURSE',
                title: payload?.title?.trim() || '',
            };

            const res = await CourseFolder.createOne(courseBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE_FOLDER', false);
        }
    },
    async putOneCourseFolder({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE_FOLDER', true);

            const id = payload.id;
            const courseBody = {
                type: 'COURSE',
                title: payload?.title?.trim() || '',
            };
            const res = await CourseFolder.updateOne(id, courseBody);

            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE_FOLDER', false);
        }
    },
    async deleteOneCourseFolder({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_COURSE_FOLDER', true);
            const res = await CourseFolder.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE_FOLDER', false);
        }
    },
    async postCreateMultiItem({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE_FOLDER', true);

            const courseBody = {
                ...payload,
                folder_id: payload.folder_id || '',
                type: 'COURSE',
                items: payload?.items,
                // email: payload?.email?.trim() || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await CourseFolder.createMultiCourse(courseBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_COURSE_FOLDER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
