import { ROUTE_API } from '../constants/routes';
import cmsUserAPI from './cmsUserAPI';
import cmsTestAPI from './cmsTestAPI';
import cmsCourseAPI from './cmsCourseAPI';
import cmsLessonAPI from './cmsLessonAPI';
import cmsDashboard from './cmsDashboardAPI';
import cmsCompanyAPI from './cmsCompanyAPI';
import cmsManagerAPI from './cmsManagerAPI';
import cmsChapterAPI from './cmsChapterAPI';
import cmsQuestionAPI from './cmsQuestionAPI';
import cmsEmployeeAPI from './cmsEmployeeAPI';
import cmsLoginHistory from './cmsLoginHistory';
import cmsNotification from './cmsNotificationAPI';
import cmsuploadExcel from './cmsuploadExcelAPi';
import cmsFolderItemAPI from './cmsFolderItemAPI';
import cmsUserFolderAPI from './cmsUserFolderAPI';
import cmsCourseFolderAPI from './cmsCourseFolderAPI';
import cmsCoursesOfUserAPI from './cmsCoursesOfUserAPI';
import cmsResultsSectionAPI from './cmsResultsSectionAPI';
import cmsChaptersOfCourseAPI from './cmsChaptersOfCourseAPI';
import cmsFaqAPI from './cmsFaqAPI';
import cmsFormQuestionAPI from './cmsFormQuestionAPI';
import cmsTutorialAPI from './cmsTutorialAPI';
import cmsTutorialDetailAPI from './cmsTutorialDetailAPI';
import cmsFolderAPI from './cmsFolderAPI';

const cmsUploadExcelApi = new cmsuploadExcel(ROUTE_API.FILE);
const CmsLoginHistory = new cmsLoginHistory(ROUTE_API.LOGIN_HISTORY);
const cmsNotificationAPI = new cmsNotification(ROUTE_API.NOTIFICATION);
const cmsPushNotificationAPI = new cmsNotification(ROUTE_API.PUSH_NOTIFICATION);

const User = new cmsUserAPI(`${ROUTE_API.USER}`);
const Course = new cmsCourseAPI(`${ROUTE_API.COURSE}`);
const Company = new cmsCompanyAPI(`${ROUTE_API.COMPANY}`);
const Manager = new cmsManagerAPI(`${ROUTE_API.MANAGER}`);
const Chapter = new cmsChapterAPI(`${ROUTE_API.CHAPTER}`);
const Dashboard = new cmsDashboard(`${ROUTE_API.DASHBOARD}`);
const Question = new cmsQuestionAPI(`${ROUTE_API.QUESTION}`);
const Employee = new cmsEmployeeAPI(`${ROUTE_API.EMPLOYEE}`);
const FolderItem = new cmsFolderItemAPI(`${ROUTE_API.FOLDER_ITEM}`);
const Test = new cmsTestAPI(`${ROUTE_API.ADD_QUESTION_TEST}`);
const UserFolder = new cmsUserFolderAPI(`${ROUTE_API.USER_FOLDER}`);
const Lesson = new cmsLessonAPI(`${ROUTE_API.ADD_QUESTION_LESSON}`);
const CourseFolder = new cmsCourseFolderAPI(`${ROUTE_API.COURSE_FOLDER}`);
const ResultsSection = new cmsResultsSectionAPI(`${ROUTE_API.TEST_ATTEND}`);
const CourseOfUser = new cmsCoursesOfUserAPI(`${ROUTE_API.USER}${ROUTE_API.COURSES_OF_USER}`);
const ChaptersOfCourse = new cmsChaptersOfCourseAPI(`${ROUTE_API.COURSE}${ROUTE_API.COURSES_OF_USER}`);
const FAQ = new cmsFaqAPI(ROUTE_API.FAQ);
const FormQuestion = new cmsFormQuestionAPI(ROUTE_API.FORM_QUESTION);
const Tutorial = new cmsTutorialAPI(ROUTE_API.TUTORIAL);
const TutorialDetail = new cmsTutorialDetailAPI(ROUTE_API.TUTORIAL_DETAIL);
const Folder = new cmsFolderAPI(ROUTE_API.FOLDER);

export {
    User,
    Test,
    Course,
    Lesson,
    Company,
    Manager,
    Chapter,
    Question,
    Employee,
    Dashboard,
    UserFolder,
    FolderItem,
    CourseFolder,
    CourseOfUser,
    ResultsSection,
    CmsLoginHistory,
    ChaptersOfCourse,
    cmsNotificationAPI,
    cmsUploadExcelApi,
    cmsPushNotificationAPI,
    FAQ,
    FormQuestion,
    Tutorial,
    TutorialDetail,
    Folder,
};
