<template lang="">
    <button class="scroll-top" v-show="scY > 300" @click="toTop">
        <v-icon class="fa-solid fa-arrow-up"></v-icon>
    </button>
</template>

<script>
export default {
    data() {
        return {
            scTimer: 0,
            scY: 0,
        };
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll: function () {
            if (this.scTimer) return;
            this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
            }, 100);
        },
        toTop: function () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth',
            });
        },
    },
};
</script>

<style scoped lang="scss">
.scroll-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    z-index: 1400;
    background-color: #fff;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0 0 6px 2px #34447c;
    & i {
        color: #000;
    }
}
</style>
