import { ROLE_USER } from '../constants';

export const paramsLoading = {
    loader: 'dots',
    color: '#1890FF',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    blur: '0',
};

export const paramsToast = (type, message) => ({
    type,
    message,
    position: 'top-right',
    duration: 3000,
});

export const exportResults = (res) => res?.data;

export const convertParams = (params) => {
    return Object.fromEntries(Object.entries(params).map(([key, value]) => [key, JSON.stringify(value)]));
};

export const debounce = (fn, ms) => {
    let timer;

    return function () {
        // Nhận các đối số
        const args = arguments;
        const context = this;

        if (timer) clearTimeout(timer);

        timer = setTimeout(() => {
            fn.apply(context, args);
        }, ms);
    };
};

export const isMobile = () => {
    if (
        /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
            navigator.userAgent,
        ) ||
        window.innerWidth === 767
    ) {
        return true;
    }
    return false;
};

export const checkIsMaster = (user) => {
    return user.role === ROLE_USER.MASTER;
};

export const checkIsManager = (user) => {
    return user.role === ROLE_USER.MANAGER;
};

export const getEnvironment = () => {
    return process.env.VUE_APP_ENVIRONMENT;
};
