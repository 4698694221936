export const ROLE_USER = {
    USER: 'USER',
    MANAGER: 'MANAGER',
    MASTER: 'MASTER',
};

export const TYPE_FAQ = {
    FUNCTION: 'FUNCTION',
    PAYMENT: 'PAYMENT',
};

export const CRUD = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    PUSH_NOTICE: 'PUSH_NOTICE',
};

export const ALERT = {
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
    PUSH_NOTICE_SUCCESS: 'PUSH_NOTICE_SUCCESS',
    CREATE_FAIL: 'CREATE_FAIL',
    UPDATE_FAIL: 'UPDATE_FAIL',
    DELETE_FAIL: 'DELETE_FAIL',
};
