import md5 from 'md5';
import { Question } from '../../../api';

const state = {
    questions: [],
    isLoadingQuestion: false,
    questionsPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    questionBody: {
        title: '',
    },
};
const mutations = {
    SET_QUESTIONS(state, questions) {
        state.questions = questions;
    },
    SET_IS_LOADING_QUESTION(state, status) {
        state.isLoadingQuestion = status;
    },
    SET_QUESTIONS_PAGINATION(state, pagination) {
        state.questionsPagination = pagination;
    },
    SET_QUESTION_BODY(state, body) {
        state.questionBody = body;
    },
};
const actions = {
    async getQuestions({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_QUESTION', true);

            // const getParamsID = this.$route.params?.id
            // const getParams = this.$route.path.split('/')[2]
            let params = {
                fields: [
                    '$all',
                    payload.getParams === 'lesson'
                        ? { sub_lesson: ['$all'] }
                        : {
                              test_questions: ['$all', { test_answers: ['$all'] }],
                          },
                ],
                order:
                    payload.getParams === 'lesson'
                        ? [['sub_lesson', 'order', 'desc']]
                        : [
                              ['test_questions', 'order', 'desc'],
                              ['test_questions', 'test_answers', 'order', 'asc'],
                          ],
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            // await HTTP.get(`${getParams}/${getParamsID}?fields=`)
            const res = await Question.getList(
                payload.getParams === 'lesson' ? 'lesson' : '/test/get_answer_correct',
                payload.getParamsID,
                params,
            );

            let questions = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.object) {
                if (payload.getParams === 'lesson') {
                    questions = res?.results?.object?.sub_lessons;
                } else {
                    questions = res?.results?.object?.test_questions;
                }
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.questionsPagination.pageSize);
            }

            commit('SET_QUESTIONS', questions);
            commit('SET_QUESTIONS_PAGINATION', {
                ...state.questionsPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_QUESTION', false);
        }
    },
    async postNewQuestion({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_QUESTION', true);

            const questionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await Question.createOne(questionBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_QUESTION', false);
        }
    },
    async putOneQuestion({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_QUESTION', true);
            const id = payload.id;
            const questionBody = {
                ...payload,
                title: payload?.title?.trim() || '',
            };

            const res = await Question.updateOne(id, questionBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_QUESTION', false);
        }
    },
    async deleteOneQuestion({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_QUESTION', true);
            const res = await Question.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_QUESTION', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
