import moment from 'moment';
const mixinFormats = {
    methods: {
        getFormatDate(fullDate) {
            if (fullDate) {
                return `${moment(fullDate).format('YYYY/MM/DD HH:mm:ss')}`;
            } else {
                return null;
            }
        },
        convertTimeZone(fullDate) {
            if (fullDate) {
                return fullDate ? `${moment(fullDate).utc().format('YYYY/MM/DD HH:mm:ss')}` : null;
            } else {
                return null;
            }
        },
        formatTimeCourse(date) {
            if (date) {
                return `${moment(date).format('YYYY/MM/DD')}`;
            } else {
                return null;
            }
        },
    },
};
export default mixinFormats;
