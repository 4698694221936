import API from './api';
import { exportResults, convertParams } from '../utils/index';
import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';

export default class Courses extends API {
    getOne = async (id, params) =>
        exportResults(
            await HTTP.get(`${this.ROUTE}/cms/${id}`, {
                params: convertParams(params),
            }),
        );

    getRecord = async (id, params) =>
        exportResults(
            await HTTP.get(`${ROUTE_API.COURSE_RECORD}/${id}`, {
                params: convertParams(params),
            }),
        );

    dowloadCourseRecord = async (body) => exportResults(await HTTP.post(ROUTE_API.FILE_DOWNLOAD_COURSE_RECORD, body));
}
