<template>
    <v-container fluid class="pa-0 ma-0">
        <v-row class="d-flex align-center" no-gutters dense>
            <v-col class="justify-center">
                <v-pagination v-model="currentPage" :length="totalPages" :total-visible="totalVisible"
                    :disabled="disabled"></v-pagination>
            </v-col>
            <!-- <v-col cols="12" md="2">
                <div class="text-right font-weight-bold" v-html="pageTitle"></div>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: 'FPagination',
    props: {
        value: {
            type: Number,
            default: 0,
            required: true,
        },
        pageSize: {
            type: Number,
            default: 0,
            required: true,
        },
        totalItems: {
            type: Number,
            default: 0,
            required: true,
        },
        totalVisible: {
            type: Number,
            default: 7,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        pageTitle() {
            const page = this.currentPage;
            const pageSize = this.pageSize;
            const totalItems = this.totalItems;

            let start = (page - 1) * pageSize;
            let end = page * pageSize;

            if (start === 0) start = 1;
            if (end > totalItems) end = totalItems;
            return ` <span class='primary--text'>${start}-${end}</span> / ${totalItems}`;
        },

        totalPages() {
            if (this.pageSize === 0) {
                return 0;
            }
            return Math.ceil(this.totalItems / this.pageSize);
        },
        currentPage: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            },
        },
    },
};
</script>
