export const ROUTE_API = {
    FILE: '/file',
    USER: '/user',
    TEST: '/test',
    MANAGER: '/user',
    COURSE: '/course',
    LESSON: '/lesson',
    RECORD: '/record',
    COMPANY: '/company',
    CHAPTER: '/chapter',
    SECTION: '/section',
    SECTION: '/section',
    DASHBOARD: '/chart',
    EMPLOYEE: '/employee',
    QUESTION: '/question',
    USER_FOLDER: '/folder',
    COURSES_OF_USER: '/record',
    FOLDER_ITEM: '/folder_item',
    TEST_RECORD: '/record/test',
    TEST_ATTEND: '/test_attend',
    NOTIFICATION: '/notification',
    USER_PROFILE: '/user/profile',
    UPLOAD_IMAGE: '/image/upload',
    COURSE_FOLDER: '/folder',
    LOGIN_HISTORY: '/login_history',
    ATTEMPT_TEST: '/test/attempt_test',
    ADD_QUESTION_LESSON: '/sub_lesson',
    SET_PASSWORD: '/auth/set_password',
    ADD_QUESTION_TEST: '/test_question',
    EMPLOYEE_PROFILE: '/employee/profile',
    PUSH_NOTIFICATION: '/push_notification',
    LOGIN_BY_MS365: '/auth/login_by_ms365_business',
    CHANGE_PASSWORD: '/user/change_password',
    FINISH_SUB_LESSON: '/sub_lesson/finished',
    CHANGE_PASSWORD_MASTER: '/employee/change_password',
    LOGIN_BY_MS365_REDIRECT: '/auth/login_by_ms365/redirect',
    FAQ: '/faq',
    FORM: '/form',
    FORM_QUESTION: '/form_question',
    ATTEMPT_FORM: '/form/attempt_form',
    TUTORIAL: '/tutorial',
    TUTORIAL_DETAIL: '/tutorial_detail',
    FORM_ATTEND_WEB: '/form_attend/web',
    TEST_RECORD_CMS: '/record/test/cms',
    FORM_ATTEND: '/form_attend',
    COURSE_RECORD: '/course/record',
    FILE_DOWNLOAD_COURSE_RECORD: '/file/download_course_record',
    TEST_RECORD_USER: '/record/test/user',
    FOLDER: '/folder',
    FOLDER_PARENT: '/folder/parent',
};
