<template>
    <v-row justify="center">
        <v-dialog v-model="isShowDialog" :width="width">
            <v-card>
                <v-card-title class="text-h5"> {{ title }} </v-card-title>
                <v-card-text> {{ content }} </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="primary"
                        class="mb-2"
                        v-for="(item, index) in actions"
                        :key="index"
                        @click="item.function"
                    >
                        {{ item.title }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
export default {
    mixins: [],
    components: {},
    props: {
        isShowDialog: {
            type: Boolean,
        },
        width: {
            type: String,
        },
        title: {
            type: String,
        },
        content: {
            type: String,
        },
        actions: {
            type: Array,
        },
    },

    data() {
        return {};
    },
};
</script>
