<template lang="">
    <div>
        <div class="box-content">
            <div class="box-title">
                <h2>{{ $t('header.notifications') }}</h2>
                <button class="btn-readAll" @click="handleReadAll" v-if="this.$store.getters.getUnreadNotice !== 0">
                    {{ $t('read_all') }}
                </button>
            </div>
            <div class="box-list" ref="noticeList">
                <div
                    class="notice"
                    v-for="(notice, index) in notifications"
                    :style="{ backgroundColor: notice.is_seen ? '#fff' : '#f1f1f1' }"
                    @click="handleClick(notice)"
                >
                    <img src="../../../assets/images/icon-notice.png" alt="icon-notice" />
                    <div>
                        <h4 class="notice__title">{{ notice.message_title }}</h4>
                        <p class="notice__desc">{{ notice.message_content }}</p>
                        <p class="notice__datetime">
                            {{ getFormatDate(+notice.created_at_unix_timestamp) }}
                        </p>
                    </div>
                </div>
                <div class="not_notice" v-if="!isLoadingNotice && notifications.length === 0">
                    <a-empty :description="$t('no_data')" />
                </div>
                <div class="box-load" v-if="!isLoadingNotice && noticePagination.totalItems > notifications.length">
                    <button @click="handleLoadMore">{{ $t('load_more') }}</button>
                </div>
                <Loading :pad="1" v-if="isLoadingNotice" />
            </div>
        </div>
    </div>
</template>

<script>
import * as UserAPI from '../../../api/userAPI';
import { mapState, mapActions } from 'vuex';
import { mixinToasts, mixinFormats } from '../../../mixins';
import Loading from '../Loading/Loading.vue';

export default {
    mixins: [mixinToasts, mixinFormats],
    props: {
        isNotice: { type: Boolean },
        closeNotice: { type: Function },
    },
    components: {
        Loading,
    },
    data() {
        return {
            currentPage: 1,
        };
    },
    async mounted() {
        await this.getNotifications();
        if (Notification.permission !== 'granted') {
            await this.getUnreadNotice();
        }
    },
    computed: {
        ...mapState('notificationWeb', ['notifications', 'isLoadingNotice', 'noticePagination']),
    },
    watch: {
        '$props.isNotice': async function () {
            if (this.isNotice) {
                this.$refs.noticeList.scroll({ top: 0 });
                this.currentPage = 1;
                await this.getNotifications();
                if (Notification.permission !== 'granted') {
                    await this.getUnreadNotice();
                }
            }
        },
    },
    methods: {
        ...mapActions('notificationWeb', ['getNotificationsAction', 'seenNoticeAction', 'seenAllNoticeAction']),

        async getUnreadNotice() {
            try {
                const res = await UserAPI.getProfile({
                    fields: ['amount_unread_notification'],
                });
                const userInfo = this.$store.getters.getUserInfo;
                const newInfo = {
                    ...userInfo,
                    amount_unread_notification: res?.results?.object?.amount_unread_notification,
                };
                this.$store.dispatch('updateInfo', newInfo);
            } catch (error) {
                this.showError(error);
            }
        },

        async getNotifications() {
            await this.getNotificationsAction({
                page: this.currentPage,
                onSuccess: (isSuccess, data) => {
                    if (!isSuccess) this.showError(data);
                },
            });
        },

        handleLoadMore() {
            this.currentPage = this.currentPage + 1;
            this.getNotifications();
        },

        async handleReadAll() {
            const userInfo = this.$store.getters.getUserInfo;

            if (userInfo.amount_unread_notification !== 0) {
                await this.seenAllNoticeAction({
                    onSuccess: (isSuccess, data) => {
                        if (isSuccess) {
                            const newInfo = {
                                ...userInfo,
                                amount_unread_notification: 0,
                            };
                            this.$store.dispatch('updateInfo', newInfo);
                        } else {
                            this.showError(data);
                        }
                    },
                });
            }
        },

        async handleClick(notice) {
            if (this.closeNotice) {
                this.closeNotice();
            }

            this.$router.push(`/notice-detail-page/${notice.id}`);

            if (!notice.is_seen) {
                await this.seenNoticeAction({
                    id: notice.id,
                    onSuccess: (isSuccess, data) => {
                        if (isSuccess) {
                            const userInfo = this.$store.getters.getUserInfo;
                            const newInfo = {
                                ...userInfo,
                                amount_unread_notification: userInfo?.amount_unread_notification - 1,
                            };
                            this.$store.dispatch('updateInfo', newInfo);
                        } else {
                            this.showError(data);
                        }
                    },
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.box-content {
    background-color: #fff;
    border-radius: 20px;
}

.box-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 30px;
    border-bottom: 1px solid #f0f0f0;
    & h2 {
        margin-bottom: 0;
        font-size: 18px;
    }
    & button {
        font-size: 14px;
        color: #007aff;
    }
}

.box-list {
    // max-height: 400px;
    overflow: auto;
    & .notice {
        display: flex;
        padding: 15px 20px;
        background-color: #fff;
        color: #000;
        cursor: pointer;
        & img {
            margin-right: 20px;
            width: 50px;
            height: 100%;
        }
        & .notice__title,
        & .notice__desc {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-word;
        }
        & .notice__title {
            margin-bottom: 8px;
            font-size: 14px;
            font-weight: 700;
        }
        & .notice__desc {
            margin-bottom: 8px;
            font-size: 13px;
            white-space: pre-line;
        }
        & .notice__datetime {
            margin-bottom: 0;
            font-size: 12px;
            color: #777;
        }
    }
    & .not_notice {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 400px;
    }
}

.box-load {
    padding: 10px 10px 20px;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #656ef1;
    transition: all 0.4s ease;
    & button {
        padding: 5px 10px;
        border-radius: 6px;
        &:hover {
            background-color: rgba(101, 110, 241, 0.1);
        }
    }
}
</style>
