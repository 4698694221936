<template>
    <a-layout-header class="web">
        <div class="wrapper">
            <div class="logo">
                <router-link to="/my-page"> <img src="../../../assets/images/logo.jpg" alt="logo" /> </router-link>
            </div>
            <div class="search-header">
                <Search />
            </div>
            <div class="right">
                <div class="desktop">
                    <router-link to="/my-page" class="header-link">
                        <span class="menu-link">{{ $t('header.home') }}</span>
                    </router-link>
                    <router-link to="/course-page" class="header-link">
                        <span class="menu-link">{{ $t('header.courses') }}</span>
                    </router-link>
                    <router-link to="/faq-page" class="header-link">
                        <span class="menu-link">{{ $t('header.faq') }}</span>
                    </router-link>
                    <a-popover overlayClassName="header-notice" v-model="isNotice" trigger="click" placement="bottom">
                        <div slot="content">
                            <Notification :isNotice="isNotice" :closeNotice="closeNotice" />
                        </div>
                        <button class="btn-notice badge-notice">
                            <a-badge :count="this.$store.getters.getUnreadNotice" :overflow-count="9">
                                <v-icon class="fa-solid fa-bell"></v-icon>
                            </a-badge>
                        </button>
                    </a-popover>
                    <!-- <a-select
                        class="select-language"
                        :default-value="this.language"
                        style="width: 120px"
                        @change="handleChangeLang"
                        v-if="isMultipleLanguage"
                    >
                        <a-select-option value="en">🇺🇸 English</a-select-option>
                        <a-select-option value="jpn">🇯🇵 Japan</a-select-option>
                    </a-select> -->
                    <a-dropdown overlayClassName="my-dropdown">
                        <div class="user">
                            <img
                                class="user__avatar"
                                :src="this.$store.getters.getAvatar"
                                v-if="this.$store.getters.getAvatar"
                            />
                            <img
                                class="user__avatar"
                                src="../../../assets/images/no_image.jpg"
                                v-else-if="!this.$store.getters.getAvatar"
                            />
                            <span class="user__name">{{ this.$store.getters.getFullName }}</span>
                        </div>
                        <template #overlay>
                            <a-menu>
                                <a-menu-item>
                                    <router-link
                                        to="/profile-page"
                                        v-bind:style="{
                                            fontSize: '16px',
                                        }"
                                    >
                                        <v-icon class="fa-solid fa-user"></v-icon>
                                        <span>{{ $t('header.profile') }}</span>
                                    </router-link>
                                </a-menu-item>
                                <a-menu-item v-if="isManager">
                                    <router-link
                                        to="/dashboard"
                                        v-bind:style="{
                                            fontSize: '16px',
                                        }"
                                    >
                                        <v-icon class="fa-solid fa-wrench"></v-icon>
                                        <span>{{ $t('header.administration') }}</span>
                                    </router-link>
                                </a-menu-item>
                                <a-menu-item @click="onLogout">
                                    <v-icon class="fa-solid fa-right-from-bracket"></v-icon>
                                    <span
                                        v-bind:style="{
                                            fontSize: '16px',
                                        }"
                                    >
                                        {{ $t('log_out') }}
                                    </span>
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </div>

                <a-button class="bar-mobile" @click="showDrawer">
                    <v-icon class="fa-solid fa-bars"></v-icon>
                </a-button>
                <a-drawer placement="right" :visible="visible" :closable="false" @close="closeDrawer">
                    <a-row type="flex" justify="space-between">
                        <button class="btn-close" @click="closeDrawer">
                            <v-icon class="fa-solid fa-xmark"></v-icon>
                        </button>
                        <div @click="closeDrawer">
                            <router-link to="/profile-page" class="user-mobile">
                                <a-avatar
                                    class="user__avatar"
                                    :src="this.$store.getters.getAvatar || 'https://picsum.photos/500'"
                                />
                                <span class="user__name">{{ this.$store.getters.getFullName }}</span>
                            </router-link>
                        </div>
                    </a-row>
                    <ul class="menu-mobile">
                        <li @click="closeDrawer">
                            <router-link to="/my-page" class="header-link">
                                <v-icon class="fa-solid fa-house"></v-icon>
                                <span class="menu-mobile__text">{{ $t('header.home') }}</span>
                            </router-link>
                        </li>
                        <li @click="closeDrawer">
                            <router-link to="/search-page" class="header-link">
                                <v-icon class="fa-solid fa-magnifying-glass"></v-icon>
                                <span class="menu-mobile__text">{{ $t('header.search') }}</span>
                            </router-link>
                        </li>
                        <li @click="closeDrawer">
                            <router-link to="/course-page" class="header-link">
                                <v-icon class="fa-solid fa-book"></v-icon>
                                <span class="menu-mobile__text">{{ $t('header.courses') }}</span>
                            </router-link>
                        </li>
                        <li @click="closeDrawer">
                            <router-link to="/faq-page" class="header-link">
                                <v-icon class="fa-solid fa-circle-question"></v-icon>
                                <span class="menu-mobile__text">{{ $t('header.faq') }}</span>
                            </router-link>
                        </li>
                        <li @click="closeDrawer">
                            <router-link to="/notice-page" class="header-link badge-notice">
                                <a-badge :count="this.$store.getters.getUnreadNotice" :overflow-count="9">
                                    <v-icon class="fa-solid fa-bell"></v-icon>
                                </a-badge>
                                <span class="menu-mobile__text">{{ $t('header.notifications') }}</span>
                            </router-link>
                        </li>
                        <li @click="closeDrawer">
                            <router-link to="/profile-page" class="header-link">
                                <v-icon class="fa-solid fa-user"></v-icon>
                                <span class="menu-mobile__text">{{ $t('header.profile') }}</span>
                            </router-link>
                        </li>
                        <li @click="onLogout">
                            <v-icon class="fa-solid fa-right-from-bracket"></v-icon>
                            <span class="menu-mobile__text">{{ $t('log_out') }}</span>
                        </li>
                    </ul>
                </a-drawer>
            </div>
        </div>
    </a-layout-header>
</template>

<script>
import Search from '../Search/Search.vue';
import Notification from '../Notification';
import { ROLE_USER } from '../../../constants';
import { getEnvironment } from '../../../utils';

export default {
    components: {
        Search,
        Notification,
    },

    data() {
        return {
            isManager: this.$store.getters.getUserInfo?.role === ROLE_USER.MANAGER,
            language: this.$store.getters.getLang,
            visible: false,
            isNotice: false,
            isMultipleLanguage: getEnvironment() === 'DEVELOP',
        };
    },

    methods: {
        showDrawer() {
            this.visible = true;
        },

        closeDrawer() {
            this.visible = false;
        },

        closeNotice() {
            this.isNotice = false;
        },

        onLogout() {
            this.$confirm({
                title: this.$t('log_out'),
                content: this.$t('confirm_log_out'),
                okText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                onOk: () => {
                    this.$store.dispatch('logout');
                },
            });
        },

        handleChangeLang(value) {
            this.$store.dispatch('setLang', value);
        },
    },
};
</script>

<style lang="scss">
.header-link.router-link-active i,
.header-link.router-link-active span {
    color: #656ef1 !important;
}

.my-dropdown {
    min-width: 250px !important;

    & .ant-dropdown-menu-item {
        padding: 0;
    }

    & .ant-dropdown-menu-item a {
        margin: 0;
        padding: 10px 15px;
    }

    & .ant-dropdown-menu-item:last-child {
        display: flex;
        align-items: center;
        padding: 10px 15px;
    }

    & .v-icon.v-icon {
        margin-right: 10px;
        font-size: 20px;
    }
}

.badge-notice {
    & .ant-scroll-number {
        height: 15px;
        min-width: 15px;
        padding: 0 5px;
        font-size: 10px;
        line-height: 15px;
        box-shadow: unset;
    }
    & .ant-scroll-number .ant-scroll-number-only-unit {
        font-size: 10px;
    }
}

.header-notice {
    position: fixed;
    & .ant-popover-inner-content {
        padding: 0;
    }
    & .ant-popover-content {
        width: 500px;
    }
    & .box-list {
        min-height: 400px;
        max-height: 500px;
        overflow: auto;
    }
}

.btn-notice {
    margin-right: 40px;
    & i {
        color: #000 !important;
    }
}

.ant-layout-header.web {
    position: fixed;
    width: 100%;
    background: #fff;
    box-shadow: 0 2px 4px rgb(0 0 0 / 8%), 0 4px 12px rgb(0 0 0 / 8%);
    z-index: 9;
}

.wrapper {
    width: 100%;
    max-width: 1470px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.logo {
    font-size: 24px;
    font-weight: bold;
    & a {
        color: #000 !important;
    }
    & img {
        width: 200px;
    }
}

.right {
    display: flex;
    align-items: center;
}

.user,
.user-mobile {
    display: flex;
    align-items: center;
    cursor: pointer;

    & span {
        font-size: 18px;
        font-weight: 500;
    }

    & .user__name {
        max-width: 110px;
        margin-left: 10px;
        color: #000;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        word-break: break-word;
    }

    & .user__avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }
}

.menu-link {
    margin-right: 40px;
    cursor: pointer;
    font-size: 18px;
    font-weight: 500;
    color: #000;
}

.bar-mobile {
    display: none;
    width: 40px;
    height: 40px;
    padding: 0;
    border: none;
    box-shadow: none !important;

    & i {
        color: #000 !important;
    }
}

.menu-mobile {
    margin-top: 30px;
    list-style: none;

    & li {
        width: 100%;
        padding: 15px 15px 15px 0;
        display: flex;
        align-items: center;
        cursor: pointer;
    }
    & li a {
        width: 100%;
        display: flex;
        align-items: center;
    }
    & li i {
        width: 30px;
        font-size: 20px;
        color: #000 !important;
    }

    & li .menu-mobile__text {
        margin-left: 15px;
        font-size: 16px;
        font-weight: 600;
        color: #000 !important;
    }
}

.desktop {
    display: flex;
    align-items: center;
}

.btn-close {
    & i {
        color: #000 !important;
    }
}

.select-language {
    margin-right: 30px;
    &.ant-select {
        height: 40px;
        box-shadow: none;
    }
    & .ant-select-selection {
        box-shadow: none;
    }
    & .ant-select-selection:hover,
    & .ant-select-selection:focus,
    &.ant-select-focused .ant-select-selection {
        border-color: #000;
    }
}

@media screen and (max-width: 1200px) {
    .desktop {
        & .header-link,
        & .user,
        & .btn-notice {
            display: none;
        }
    }

    .bar-mobile {
        display: block;

        & i {
            color: #000;
        }
    }

    .select-language {
        margin-right: 15px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
    .logo {
        & img {
            width: 175px;
        }
    }
}

@media screen and (max-width: 767px) {
    .ant-layout-header.web {
        padding: 0;
    }

    .logo {
        & img {
            width: 150px;
        }
    }

    .search-header {
        display: none;
    }

    .btn-notice {
        display: none;
    }
}
</style>
