import * as TYPE from './types';
import app from '../../main';
import { checkIsMaster } from '../../utils';

const moduleAuth = {
    state: () => ({
        userInfo: JSON.parse(localStorage.getItem('userInfo')) || null,
        companyCode: localStorage.getItem('companyCode') || null,
    }),

    getters: {
        getUserInfo(state) {
            return state?.userInfo?.result;
        },
        getFullName(state) {
            return state?.userInfo?.result?.full_name;
        },
        getAvatar(state) {
            return state?.userInfo?.result?.avatar;
        },
        getCompanyCode(state) {
            return state?.userInfo?.company_code;
        },
        getUnreadNotice(state) {
            return state?.userInfo?.result?.amount_unread_notification || 0;
        },
    },

    mutations: {
        [TYPE.SET_INFO](state, payload) {
            localStorage.setItem('userInfo', JSON.stringify(payload));
            localStorage.setItem('accessToken', payload.token);
            localStorage.setItem('refreshToken', payload.refreshToken);
            state.userInfo = payload;
        },
        [TYPE.REMOVE_INFO](state) {
            let link = '';

            if (checkIsMaster(state.userInfo.result)) {
                link = '/admin';
            } else {
                link = `/sign-in/${state.userInfo.company_code}`;
            }

            localStorage.removeItem('userInfo');
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            state.userInfo = null;
            app.$router.push(link);
        },
        [TYPE.UPDATE_INFO](state, payload) {
            const newData = {
                ...state.userInfo,
                result: payload,
            };
            localStorage.setItem('userInfo', JSON.stringify(newData));
            state.userInfo = newData;
        },
        [TYPE.UPDATE_ACCESS_AND_REFRESH](state, payload) {
            const newData = {
                ...state.userInfo,
                token: payload.token,
                refreshToken: payload.refreshToken,
            };
            localStorage.setItem('accessToken', payload.token);
            localStorage.setItem('refreshToken', payload.refreshToken);
            state.userInfo = newData;
        },
    },

    actions: {
        login(context, data) {
            context.commit(TYPE.SET_INFO, data);
        },
        logout(context) {
            context.commit(TYPE.REMOVE_INFO);
        },
        updateInfo(context, data) {
            context.commit(TYPE.UPDATE_INFO, data);
        },
        updateAccessAndRefresh(context, data) {
            context.commit(TYPE.UPDATE_ACCESS_AND_REFRESH, data);
        },
        setCompanyCode(context, data) {
            context.commit(TYPE.SET_COMPANY_CODE, data);
        },
    },
};

export default moduleAuth;
