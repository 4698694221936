import * as NotificationAPI from '../../../api/notificationAPI';

const state = {
    notifications: [],
    noticeObject: {},
    isLoadingNotice: false,
    noticePagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};

const mutations = {
    SET_NOTIFICATIONS(state, list) {
        state.notifications = list;
    },
    SET_NOTICE_OBJECT(state, notice) {
        state.noticeObject = notice;
    },
    SET_IS_LOADING_NOTICE(state, value) {
        state.isLoadingNotice = value;
    },
    SET_NOTICE_PAGINATION(state, pagination) {
        state.noticePagination = pagination;
    },
};

const actions = {
    // payload: { page: number, onSuccess: (boolean, msgErr) => { } }
    async getNotificationsAction({ commit, state }, payload) {
        const { page, onSuccess } = payload;
        try {
            commit('SET_IS_LOADING_NOTICE', true);

            const res = await NotificationAPI.getList({
                fields: ['$all'],
                page: page || state.noticePagination.page,
                limit: state.noticePagination.pageSize,
            });

            let notifications = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                notifications =
                    page === 1 ? res?.results?.objects?.rows : [...state.notifications, ...res?.results?.objects?.rows];
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.noticePagination.pageSize);
            }

            commit('SET_NOTIFICATIONS', notifications);
            commit('SET_NOTICE_PAGINATION', {
                ...state.noticePagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
            onSuccess(true);
        } catch (error) {
            onSuccess(false, error?.response?.data?.message);
        } finally {
            commit('SET_IS_LOADING_NOTICE', false);
        }
    },

    // payload: { id: number, onSuccess: (boolean, msgErr) => { } }
    async getOneNoticeAction({ commit }, payload) {
        const { id, onSuccess } = payload;
        try {
            commit('SET_IS_LOADING_NOTICE', true);

            const res = await NotificationAPI.findOne(id, {
                fields: ['$all'],
            });

            commit('SET_NOTICE_OBJECT', res?.results?.object);
            onSuccess(true);
        } catch (error) {
            onSuccess(false, error?.response?.data?.message);
        } finally {
            commit('SET_IS_LOADING_NOTICE', false);
        }
    },

    // payload: { id: number, onSuccess: (boolean, msgErr) => { } }
    async seenNoticeAction({ commit }, payload) {
        const { id, onSuccess } = payload;
        try {
            commit('SET_IS_LOADING_NOTICE', true);

            await NotificationAPI.seen(id);

            onSuccess(true);
        } catch (error) {
            onSuccess(false, error?.response?.data?.message);
        } finally {
            commit('SET_IS_LOADING_NOTICE', false);
        }
    },

    // payload: { onSuccess: (boolean, msgErr) => { } }
    async seenAllNoticeAction({ commit, state }, payload) {
        const { onSuccess } = payload;
        try {
            commit('SET_IS_LOADING_NOTICE', true);

            await NotificationAPI.seenAll();

            const newList = state.notifications.map((notice) => ({ ...notice, is_seen: true }));

            onSuccess(true);
            commit('SET_NOTIFICATIONS', newList);
        } catch (error) {
            onSuccess(false, error?.response?.data?.message);
        } finally {
            commit('SET_IS_LOADING_NOTICE', false);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
