<template>
    <!-- Main Sidebar -->
    <component :is="navbarFixed ? 'a-affix' : 'div'" :offset-top="top">
        <!-- Layout Header -->
        <a-layout-header>
            <a-row type="flex">
                <!-- Header Breadcrumbs & Title Column -->
                <a-col :span="24" :md="18">
                    <v-breadcrumbs :items="breadCrumbs">
                        <template v-slot:item="{ item }">
                            <v-breadcrumbs-item
                                :to="item.to"
                                class="text-subtitle-2 crumb-item"
                                :disabled="item.disabled"
                                exact
                            >
                                {{ $t(`table_title.${item.text}`) }}
                            </v-breadcrumbs-item>
                        </template>
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                    <!-- / Header Breadcrumbs -->
                </a-col>
                <!-- / Header Breadcrumbs & Title Column -->

                <!-- Header Control Column -->
                <a-col :span="24" :md="6" class="header-control">
                    <a-button
                        type="link"
                        class="sidebar-toggler"
                        @click="$emit('toggleSidebar', !sidebarCollapsed), resizeEventHandler()"
                    >
                        <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                            <path
                                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
                            />
                        </svg>
                    </a-button>
                    <router-link
                        to="/profile"
                        class="btn-profile btn-sign-in"
                        @click="(e) => e.preventDefault()"
                        v-if="isMaster"
                    >
                        <div class="icon-account">
                            <i class="mdi mdi-account-circle"></i>
                        </div>
                        <span>{{ this.$store.getters.getFullName || 'Master' }}</span>
                    </router-link>
                    <router-link to="/my-page" class="btn-profile btn-sign-in" v-if="isManager">
                        <div class="icon-student">
                            <i class="mdi mdi-account-school"></i>
                        </div>
                        <span class="font-bold">{{ $t('cms.student') }}</span>
                    </router-link>
                    <!-- / Header Control Buttons -->
                </a-col>
                <!-- / Header Control Column -->
            </a-row>
        </a-layout-header>
        <!--  /Layout Header -->
    </component>
    <!-- / Main Sidebar -->
</template>

<script>
import { checkIsManager, checkIsMaster } from '../../../utils';

export default {
    props: {
        // Header fixed status.
        navbarFixed: {
            type: Boolean,
            default: false,
        },

        // Sidebar collapsed status.
        sidebarCollapsed: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            // Fixed header/sidebar-footer ( Affix component ) top offset.
            top: 0,

            // Search input loading status.
            searchLoading: false,

            // The wrapper element to attach dropdowns to.
            wrapper: document.body,

            isMaster: checkIsMaster(this.$store.getters.getUserInfo),
            isManager: checkIsManager(this.$store.getters.getUserInfo),

            items: {},
        };
    },
    methods: {
        resizeEventHandler() {
            this.top = this.top ? 0 : -0.01;
            // To refresh the header if the window size changes.
            // Reason for the negative value is that it doesn't activate the affix unless
            // scroller is anywhere but the top of the page.
        },
        onSearch(value) {},
    },
    mounted: function () {
        // Set the wrapper to the proper element, layout wrapper.
        this.wrapper = document.getElementById('layout-dashboard');
    },
    computed: {
        breadCrumbs() {
            if (typeof this.$route.meta.breadCrumb === 'function') {
                return this.$route.meta.breadCrumb.call(this, this.$route);
            }
            return this.$route.meta.breadCrumb;
        },
    },
    created() {
        // Registering window resize event listener to fix affix elements size
        // error while resizing.
        window.addEventListener('resize', this.resizeEventHandler);
    },
    destroyed() {
        // Removing window resize event listener.
        window.removeEventListener('resize', this.resizeEventHandler);
    },
};
</script>

<style scoped lang="scss">
.icon-account,
.icon-student {
    display: inline-flex;
    width: 32px;
    height: 32px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgb(0 0 0 / 12%);
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    margin: 0 11px 0 0;
    vertical-align: middle;

    & i {
        color: #000;
        font-size: 20px;
    }
}

.icon-student {
    margin-right: 10px;
}
</style>
