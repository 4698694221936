import md5 from 'md5';
import { User } from '../../../api';

const state = {
    users: [],
    isLoadingUser: false,
    usersPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    userBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: null,
        role: '',
    },
};
const mutations = {
    SET_USERS(state, users) {
        state.users = users;
    },
    SET_IS_LOADING_USER(state, status) {
        state.isLoadingUser = status;
    },
    SET_USERS_PAGINATION(state, pagination) {
        state.usersPagination = pagination;
    },
    SET_USER_BODY(state, body) {
        state.userBody = body;
    },
};
const actions = {
    async getUsers({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER', true);

            let params = {
                fields: ['$all', { company: ['company_code', 'email'] }],
                order: [['created_at', 'desc']],
                page: payload?.page || state.usersPagination.page,
                limit: payload?.limit || state.usersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await User.getList(params);

            let users = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                users = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.usersPagination.pageSize);
            }

            commit('SET_USERS', users);
            commit('SET_USERS_PAGINATION', {
                ...state.usersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_USER', false);
        }
    },
    async postNewUser({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER', true);

            const userBody = {
                ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                password: md5(payload?.password?.trim()) || '',
            };

            const res = await User.createOne(userBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER', false);
        }
    },
    async putOneUser({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_USER', true);
            const id = payload.id;
            const userBody = {
                // ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                gender: payload?.gender || null,
                role: payload?.role || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await User.updateOne(id, userBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER', false);
        }
    },
    async deleteOneUser({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_USER', true);
            const res = await User.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_USER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
