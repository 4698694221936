import md5 from 'md5';
import { ChaptersOfCourse } from '../../../api';
import * as cmsChaptersOfCourseAPI from '../../../api/cmsChaptersOfCourseAPI';

const state = {
    chaptersOfCourses: [],
    isLoadingChaptersOfCourse: false,
    chaptersOfCoursePagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    chaptersOfCourseBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: '',
        role: '',
    },
};
const mutations = {
    SET_CHAPTERS_OF_COURSES(state, chaptersOfCourses) {
        state.chaptersOfCourses = chaptersOfCourses;
    },
    SET_IS_LOADING_CHAPTERS_OF_COURSE(state, status) {
        state.isLoadingChaptersOfCourse = status;
    },
    SET_CHAPTERS_OF_COURSE_PAGINATION(state, pagination) {
        state.chaptersOfCoursePagination = pagination;
    },
    SET_CHAPTERS_OF_COURSE_BODY(state, body) {
        state.chaptersOfCourseBody = body;
    },
};
const actions = {
    async getChaptersOfCourses({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', true);

            let params = {
                fields: ['$all'],
                order: [['created_at', 'desc']],
                page: payload?.page || state.chaptersOfCoursePagination.page,
                limit: state.chaptersOfCoursePagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [payload?.field]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await ChaptersOfCourse.getListChapterOfCourse(payload.course_id, payload.user_id, params);

            let chaptersOfCourses = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.object) {
                chaptersOfCourses = res?.results?.object;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.chaptersOfCoursePagination.pageSize);
            }

            commit('SET_CHAPTERS_OF_COURSES', chaptersOfCourses);
            commit('SET_CHAPTERS_OF_COURSE_PAGINATION', {
                ...state.chaptersOfCoursePagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', false);
        }
    },
    async postNewChaptersOfCourse({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', true);

            const chaptersOfCourseBody = {
                ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                password: md5(payload?.password?.trim()) || '',
            };

            const res = await ChaptersOfCourse.findOne(chaptersOfCourseBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', false);
        }
    },
    async putOneChaptersOfCourse({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', true);
            const id = payload.id;
            const chaptersOfCourseBody = {
                // ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                gender: payload?.gender || '',
                role: payload?.role || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await cmsChaptersOfCourseAPI.updateOne(id, chaptersOfCourseBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', false);
        }
    },
    async deleteOneChaptersOfCourse({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', true);
            const res = await cmsChaptersOfCourseAPI.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err?.response?.data };
        } finally {
            commit('SET_IS_LOADING_CHAPTERS_OF_COURSE', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
