<template>
    <v-app id="app">
        <a-config-provider :locale="locale">
            <component :is="layout">
                <router-view />
            </component>
        </a-config-provider>
    </v-app>
</template>

<script>
import localeJP from 'ant-design-vue/es/locale/ja_JP';
import localEN from 'ant-design-vue/es/locale/en_US';
import * as UserAPI from './api/userAPI';
import { Employee } from './api';
import { checkIsMaster } from './utils';
import { firebaseMessaging } from './utils/setupFirebaseMessage';
import { mixinToasts } from './mixins';

export default {
    mixins: [mixinToasts],
    data() {
        return {
            locale: localeJP,
        };
    },
    watch: {
        '$store.state.lang.language': function () {
            const currentLanguage = this.$store.getters.getLang;
            this.locale = currentLanguage === 'en' ? localEN : localeJP;
        },
    },
    computed: {
        layout() {
            return 'layout-' + (this.$route.meta.layout || 'default').toLowerCase();
        },
    },
    mounted() {
        const userInfo = this.$store.getters.getUserInfo;
        if (userInfo) {
            this.getInfo(userInfo.role);
            firebaseMessaging(userInfo?.company_id, (message) => {
                if (message && this.$route.name === 'WEB') {
                    this.updateUnreadNotice();
                    this.$notification.open({
                        message: <h6 class="line-clamp-2 mb-0">{message?.data?.title}</h6> || 'Notification Title',
                        description:
                            (
                                <p class="line-clamp-4" style="white-space: pre-line">
                                    {message?.data?.message}
                                </p>
                            ) || 'Notification Content',
                        icon: <i class="fa-solid fa-bell"></i>,
                        duration: 2,
                    });
                }
            });
        }
    },
    methods: {
        async getInfo(role) {
            if (role) {
                try {
                    let response = {};

                    if (checkIsMaster({ role })) {
                        response = await Employee.getProfile();
                    } else {
                        response = await UserAPI.getProfile({ fields: ['$all'] });
                    }
                    this.$store.dispatch('updateInfo', response?.results?.object);
                } catch (error) {
                    this.showError(error);
                    if (error?.response?.data?.code === 404) {
                        this.$store.dispatch('logout');
                    }
                }
            }
        },

        updateUnreadNotice() {
            const userInfo = this.$store.getters.getUserInfo;
            const newInfo = {
                ...userInfo,
                amount_unread_notification: (userInfo?.amount_unread_notification || 0) + 1,
            };

            this.$store.dispatch('updateInfo', newInfo);
        },
    },
};
</script>

<style lang="scss"></style>
