import { HTTP } from '../config/http-common';
import { ROUTE_API } from '../constants/routes';
import { exportResults, convertParams } from '../utils/index';

export const getList = async (params) =>
    exportResults(
        await HTTP.get(ROUTE_API.SECTION, {
            params: convertParams(params),
        }),
    );

export const deleteMultiSections = async (arr) => exportResults(await HTTP.delete(`${ROUTE_API.SECTION}?items=${arr}`));

export const deleteOneLesson = async (id) => exportResults(await HTTP.delete(`${ROUTE_API.LESSON}/${id}`));
export const deleteOneTest = async (id) => exportResults(await HTTP.delete(`${ROUTE_API.TEST}/${id}`));
export const deleteOneForm = async (id) => exportResults(await HTTP.delete(`${ROUTE_API.FORM}/${id}`));

export const updateOneLesson = async (id, body) => exportResults(await HTTP.put(`${ROUTE_API.LESSON}/${id}`, body));
export const updateOneTest = async (id, body) => exportResults(await HTTP.put(`${ROUTE_API.TEST}/${id}`, body));
export const updateOneForm = async (id, body) => exportResults(await HTTP.put(`${ROUTE_API.FORM}/${id}`, body));

export const createOneLesson = async (body) => exportResults(await HTTP.post(`${ROUTE_API.LESSON}`, body));
export const createOneTest = async (body) => exportResults(await HTTP.post(`${ROUTE_API.TEST}`, body));
export const createOneForm = async (body) => exportResults(await HTTP.post(`${ROUTE_API.FORM}`, body));

// export const deleteMultiLessons = async (arr) => exportResults(await HTTP.delete(`${ROUTE_API.LESSON}?items=${arr}`));
// export const deleteMultiTests = async (arr) => exportResults(await HTTP.delete(`${ROUTE_API.TEST}?items=${arr}`));
