import { TutorialDetail } from '../../../api';

const state = {
    tutorialDetailObject: {},
    tutorialDetailList: [],
    isLoadingTutorialDetail: false,
    tutorialDetailPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_TUTORIAL_DETAIL_OBJECT(state, values) {
        state.tutorialDetailObject = values;
    },
    SET_TUTORIAL_DETAIL_LIST(state, values) {
        state.tutorialDetailList = values;
    },
    SET_IS_LOADING_TUTORIAL_DETAIL(state, status) {
        state.isLoadingTutorialDetail = status;
    },
    SET_TUTORIAL_DETAIL_PAGINATION(state, pagination) {
        state.tutorialDetailPagination = pagination;
    },
};
const actions = {
    // payload: { tutorial_id: string }
    async getTutorialDetailList({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_TUTORIAL_DETAIL', true);

            let params = {
                fields: ['$all'],
                where: { tutorial_id: payload.tutorial_id },
                order: [['step', 'asc']],
            };

            const res = await TutorialDetail.getList(params);

            commit('SET_TUTORIAL_DETAIL_LIST', res?.results?.objects?.rows);
        } catch (err) {
            console.log(err);
        } finally {
            commit('SET_IS_LOADING_TUTORIAL_DETAIL', false);
        }
    },

    async postTutorialDetail({ commit }, payload) {
        try {
            const body = { ...payload };

            await TutorialDetail.createOne(body);

            return { isSuccess: true, message: 'Create successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async putTutorialDetail({ commit }, payload) {
        try {
            const body = { ...payload };

            await TutorialDetail.updateOne(payload?.id, body);

            return { isSuccess: true, message: 'Update successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },

    async deleteTutorialDetail({ commit }, id) {
        try {
            await TutorialDetail.deleteOne(id);
            return { isSuccess: true, message: 'Delete successfully' };
        } catch (error) {
            return { isSuccess: false, message: error };
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
