import { initializeApp } from 'firebase/app';

const firebaseConfig = {
    apiKey: 'AIzaSyAcmEMsG6xs6oR8So-NwzNFvw3c1GFt_ww',
    authDomain: 'elearning-jp.firebaseapp.com',
    databaseURL: 'https://elearning-jp-default-rtdb.asia-southeast1.firebasedatabase.app',
    projectId: 'elearning-jp',
    storageBucket: 'elearning-jp.appspot.com',
    messagingSenderId: '285801736607',
    appId: '1:285801736607:web:8643cfcab6b26c9dad35aa',
    measurementId: 'G-NQW59H2C7J',
};

const app = initializeApp(firebaseConfig);

export default app;
