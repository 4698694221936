export const mixinRules = {
    data() {
        return {
            requiredRule: (v) => {
                if (v === null || v === '' || v === undefined || !v?.toString()?.trim()) {
                    return this.$t('form.required');
                }
                return true;
            },
            datetimeFormatRule: (v) => {
                if (v !== null && v.length > 0) {
                    let parsedDate = Date.parse(v);
                    return !isNaN(parsedDate) || 'Invalid date.';
                }
                return true;
            },
            maxLengthRule: (max) => (v) => {
                if (!v) return true;
                else if (v.length > max) {
                    return `Max ${max} characters`;
                }
                return true;
            },

            minLengthRule: (min) => (v) => {
                if (!v) return true;
                else if (v.length < min) {
                    return `Min ${min} characters`;
                }
                return true;
            },
            telFaxRule: (v) => {
                if (v !== null && v.length > 0) {
                    const format = /[_ABCDEFGHIJKLMNOPQRSTUVWXYZ]/;
                    return !format.test(v) || 'Must be valid tel.';
                }
                return true;
            },
            emailRule: (v) => {
                if (v !== null && v?.length > 0) {
                    const pattern =
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    return pattern.test(v) || this.$t('form.incorrect_email_format');
                }
                return true;
            },

            passwordRule:
                ({ messageRequired }) =>
                (v) => {
                    const alphabetReg = new RegExp('[A-Za-z]');
                    const numberReg = new RegExp('[0-9]');

                    if (!messageRequired) {
                        messageRequired = '入力してください。';
                    }

                    if (!v) {
                        return messageRequired;
                    } else if (v?.length < 8 || !alphabetReg.test(v) || !numberReg.test(v)) {
                        // return `Min ${8} characters`
                        return 'パスワードは半角英数8文字以上で入力してください。';
                    } else if (v?.length > 50) {
                        return `Max ${50} characters`;
                    }
                    return true;
                },

            sameAsRule:
                ({ target, message }) =>
                (value) => {
                    if (!message) {
                        message = 'Value does not match!';
                    }
                    if (value !== target) {
                        return message;
                    }
                    return true;
                },

            seqRule: (v) => {
                const value = Number(v);
                if (!isNaN(value)) {
                    if (value < 1) {
                        return 'Value must be greater than 0 and less than 100.';
                    } else if (value > 99) {
                        return 'Value must be greater than 0 and less than 100.';
                    }
                }
                return true;
            },

            urlRule: (v) => {
                return true;
            },
            minRule:
                ({ min, less, greater }) =>
                (value) => {
                    const _value = Number(value);
                    const _min = Number(min);
                    const _less = Number(less);
                    const _greater = Number(greater);
                    if (value) {
                        if (_value < _min) {
                            return this.$t('form.min_value', { value: _min });
                        } else if (_value > _less) {
                            return `Value must be less than ${_less}`;
                        } else if (_value < _greater) {
                            return `Value must be greater than ${_greater}`;
                        }
                    }
                    return true;
                },
            maxRule:
                ({ max, less, greater }) =>
                (value) => {
                    const _value = Number(value);
                    const _max = Number(max);
                    const _less = Number(less);
                    const _greater = Number(greater);
                    if (_value > _max) {
                        return this.$t('form.max_value', { value: _max });
                    } else if (_value > _less) {
                        return `Value must be less than ${_less}`;
                    } else if (_value < _greater) {
                        return `Value must be greater than ${_greater}`;
                    }
                    return true;
                },

            minValueRule: (min) => (value) => {
                const _value = Number(value);
                const _min = Number(min);
                if (_value < _min) {
                    return this.$t('form.min_value', { value: _min });
                }
                return true;
            },
            maxValueRule: (max) => (value) => {
                const _value = Number(value);
                const _max = Number(max);
                if (_value > _max) {
                    return this.$t('form.max_value', { value: _max });
                }
                return true;
            },

            lessValueRule: (less) => (value) => {
                const _value = Number(value);
                const _less = Number(less);
                if (_value > _less) {
                    return `Value must be less than ${_less}`;
                }
                return true;
            },
            greaterValueRule: (greater) => (value) => {
                const _value = Number(value);
                const _greater = Number(greater);
                if (_value < _greater) {
                    return `Value must be greater than ${_greater}`;
                }
                return true;
            },
            greaterValueTimeRule: (startTime) => (value) => {
                const _value = Date.parse(value);
                const _start = Date.parse(startTime);
                if (_value < _start) {
                    return `End Time value must be greater than Start Time`;
                }
                return true;
            },
            lessValueTimeRule: (endTime) => (value) => {
                const _value = Date.parse(value);
                const _end = Date.parse(endTime);
                if (_value > _end) {
                    return `Start Time value must be less than End Time`;
                }
                return true;
            },
            linkImgRule: (link) => {
                if (link && (link.startsWith('http') || link.startsWith('https'))) {
                    return true;
                }
                return false;
            },
            isImageFile(filename) {
                const ext = filename?.name.split('.').pop();
                switch (ext.toLowerCase()) {
                    case 'jpeg':
                    case 'jpg':
                    case 'png':
                    case 'heic':
                    case 'gif':
                        return true;
                    default:
                        return false;
                }
            },
        };
    },
};

export default mixinRules;
