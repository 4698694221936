import md5 from 'md5';
import { Manager } from '../../../api';

const state = {
    managers: [],
    isLoadingManager: false,
    managersPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
    managerBody: {
        avatar: '',
        full_name: '',
        email: '',
        password: '',
        gender: '',
        role: '',
    },
};
const mutations = {
    SET_MANAGERS(state, managers) {
        state.managers = managers;
    },
    SET_IS_LOADING_MANAGER(state, status) {
        state.isLoadingManager = status;
    },
    SET_MANAGERS_PAGINATION(state, pagination) {
        state.managersPagination = pagination;
    },
    SET_MANAGER_BODY(state, body) {
        state.managerBody = body;
    },
};
const actions = {
    async getManagers({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_MANAGER', true);

            let params = {
                fields: ['$all', { company: ['company_code', 'email'] }],
                where: {
                    $and: [{ role: payload?.type }],
                },
                order: [['created_at', 'desc']],
                page: payload?.page || state.managersPagination.page,
                limit: state.managersPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where.$and = [...params.where.$and, { [payload?.field]: { $iLike: `%${payload?.search}%` } }];
            }

            if (payload?.company_id) {
                params.where.$and = [...params.where.$and, { company_id: payload?.company_id }];
            }

            const res = await Manager.getList(params);

            let managers = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                managers = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.managersPagination.pageSize);
            }

            commit('SET_MANAGERS', managers);
            commit('SET_MANAGERS_PAGINATION', {
                ...state.managersPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING_MANAGER', false);
        }
    },
    async postNewManager({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_MANAGER', true);

            const managerBody = {
                ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                password: md5(payload?.password?.trim()) || '',
            };

            const res = await Manager.createOne(managerBody);
            return { isSuccess: true, mess: 'Created Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Created Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_MANAGER', false);
        }
    },
    async putOneManager({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_MANAGER', true);
            const id = payload.id;
            const managerBody = {
                // ...payload,
                avatar: payload.avatar || '',
                full_name: payload?.full_name?.trim() || '',
                email: payload?.email?.trim() || '',
                gender: payload?.gender || '',
                role: payload?.role || '',
                // password: md5(payload?.password?.trim()) || '',
            };

            const res = await Manager.updateOne(id, managerBody);
            return { isSuccess: true, mess: 'Updated Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Updated Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_MANAGER', false);
        }
    },
    async deleteOneManager({ commit, state }, id) {
        try {
            commit('SET_IS_LOADING_MANAGER', true);
            const res = await Manager.deleteOne(id);
            return { isSuccess: true, mess: 'Deleted Success!', data: res?.results?.object };
        } catch (err) {
            console.log(err);
            return { isSuccess: false, mess: 'Deleted Fail!', data: err };
        } finally {
            commit('SET_IS_LOADING_MANAGER', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
