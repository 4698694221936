import firebaseApp from '../config/firebase';
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';

const subscribeTopic = async (token, topic) => {
    let res;

    await fetch('https://iid.googleapis.com/iid/v1/' + token + '/rel/topics/' + topic, {
        method: 'POST',
        headers: new Headers({
            Authorization:
                'key=' +
                'AAAAQoscaZ8:APA91bEzUnahK0BlOiZXsNlcrPTFgQkcYWUkeoC9uWr7em5OB0jXGGUvZW1T2NyQ5Ex0xacvNitHWUEBAKRoScjc-PBAJQyVf5UPlq9x_tOrEYG0_Kg0hG7tYX2oNmxh1S4xXcbymY64',
        }),
    })
        .then((response) => {
            if (response.status < 200 || response.status >= 400) {
                throw 'Error subscribing to topic: ' + response.status + ' - ' + response.text();
            }
            res = true;
        })
        .catch((error) => {
            console.error(error);
            res = false;
        });

    return res;
};

const firebaseMessaging = (companyCode, callback) => {
    isSupported()
        .then((result) => {
            if (result) {
                const messaging = getMessaging(firebaseApp);
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker
                        .register('../firebase-messaging-sw.js')
                        .then(() => Notification.requestPermission())
                        .then((permission) => {
                            if (permission === 'granted') {
                                return getToken(messaging, {
                                    vapidKey:
                                        'BNqikUYUN4al-6ICIulVNC_XpeSrB_R6nNVj9chSXWzLDycg2x-E28F8sUc7Rqrz4SHRBh6KbSlIxzR2vnmE9EY',
                                });
                            }
                        })
                        .then(async (token) => {
                            if (token) {
                                let status;
                                if (process.env.VUE_APP_ENVIRONMENT === 'PRODUCTION') {
                                    status = await subscribeTopic(token, `${companyCode}_DEV`);
                                } else {
                                    status = await subscribeTopic(token, `${companyCode}`);
                                }
                                if (status) {
                                    onMessage(messaging, (message) => {
                                        callback(message);
                                    });
                                } else {
                                    console.log(`FAIL: SUBSCRIBE TOPIC ${companyCode}`);
                                }
                            } else {
                                console.log('No registration token available. Request permission to generate one.');
                            }
                        })
                        .catch((error) => {
                            console.log('🚀 ~ file: setupFirebaseMessage.js:49 ~ .then ~ error:', error);
                        });
                }
            }
        })
        .catch(function (error) {
            console.log('🚀 ~ file: setupFirebaseMessage.js:15 ~ firebaseMessaging ~ error:', error);
        });
};

export { firebaseMessaging };
