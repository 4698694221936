import { HTTP } from '../config/http-common';
import { exportResults, convertParams } from '../utils/index';
import API from './api';

export const getList = async (getParams, getParamsID, params) =>
    exportResults(
        await HTTP.get(`${getParams}/${getParamsID}`, {
            params: convertParams(params),
        }),
    );

export default class QuestionApi extends API {
    getList = async (getParams, getParamsID, params) =>
        exportResults(
            await HTTP.get(`${getParams}/${getParamsID}`, {
                params: convertParams(params),
            }),
        );
}
// export const findOne = async (id, params) =>
//     exportResults(
//         await HTTP.get(`${ROUTE_API.QUESTION}/${id}`, {
//             params: convertParams(params),
//         }),
//     );

// export const deleteOne = async (id) => exportResults(await HTTP.delete(`${ROUTE_API.QUESTION}/${id}`));

// export const updateOne = async (id, body) => exportResults(await HTTP.put(`${ROUTE_API.QUESTION}/${id}`, body));

// export const createOne = async (body) => exportResults(await HTTP.post(`${ROUTE_API.QUESTION}`, body));
