import { Course } from '../../../api';

const state = {
    courseRecordList: [],
    isLoadingCourseRecord: false,
    courseRecordPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_COURSE_RECORD_LIST(state, list) {
        state.courseRecordList = list;
    },
    SET_IS_LOADING_COURSE_RECORD(state, status) {
        state.isLoadingCourseRecord = status;
    },
    SET_COURSE_RECORD_PAGINATION(state, pagination) {
        state.courseRecordPagination = pagination;
    },
};
const actions = {
    // payload: { course_id: string }
    async getCourseRecordList({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING_COURSE_RECORD', true);

            let params = {
                fields: ['$all', { user: ['$all', '$paranoid'] }],
                order: [['progress', 'desc']],
                page: payload?.page || state.courseRecordPagination.page,
                limit: payload?.limit || state.courseRecordPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                if (payload?.field === 'progress') {
                    params.where = {
                        progress: payload?.search,
                    };
                } else {
                    params.where = {
                        [`$user.${payload?.field}$`]: { $iLike: `%${payload?.search}%` },
                    };
                }
            }

            const res = await Course.getRecord(payload?.course_id, params);

            let list = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                list = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.courseRecordPagination.pageSize);
            }

            commit('SET_COURSE_RECORD_LIST', list);
            commit('SET_COURSE_RECORD_PAGINATION', {
                ...state.courseRecordPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });

            return { isSuccess: true, mess: 'Get List Success!' };
        } catch (error) {
            return { isSuccess: false, mess: error };
        } finally {
            commit('SET_IS_LOADING_COURSE_RECORD', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
