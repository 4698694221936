<!-- 
	This is the default layout, used in sign-in and sign-up pages.
 -->

<template>
    <div>
        <!-- Default Layout -->
        <a-layout class="layout">
            <!-- Layout Header ( Navbar ) -->
            <HeaderUser></HeaderUser>
            <!-- / Layout Header ( Navbar ) -->

            <!-- Page Content -->
            <a-layout-content>
                <router-view />
            </a-layout-content>
            <!-- / Page Content -->

            <!-- Layout Footer -->
            <FooterUser></FooterUser>
            <!-- / Layout Footer -->
        </a-layout>
        <!-- / Default Layout -->

        <ScrollTop />
    </div>
</template>

<script>
import HeaderUser from '../../components/Web/Headers/HeaderUser.vue';
import FooterUser from '../../components/Web/Footers/FooterUser.vue';
import ScrollTop from '../../components/Web/ScrollTop/ScrollTop.vue';

export default {
    components: {
        HeaderUser,
        FooterUser,
        ScrollTop,
    },

    computed: {
        // Sets layout's element's class based on route's meta data.
        layoutClass() {
            return this.$route.meta.layoutClass;
        },
    },
};
</script>

<style scoped>
.ant-layout-content {
    width: 100%;
    min-height: calc(100vh - 134px);
    margin: 65px auto 0;
}
</style>
