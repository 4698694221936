import { CmsLoginHistory } from '../../../api';
import app from '../../../main';
import { checkIsMaster } from '../../../utils';

const state = {
    loginHistory: [],
    isLoading: false,
    LoginHistoryPagination: {
        page: 1,
        pageSize: 10,
        totalItems: 0,
        totalPages: 0,
    },
};
const mutations = {
    SET_LOGIN_HISTORY(state, values) {
        state.loginHistory = values;
    },
    SET_IS_LOADING(state, status) {
        state.isLoading = status;
    },

    SET_LOGIN_HISTORY_PAGINATION(state, pagination) {
        state.LoginHistoryPagination = pagination;
    },
};
const actions = {
    async getLoginHistories({ commit, state }, payload) {
        try {
            commit('SET_IS_LOADING', true);

            let params = {
                fields: checkIsMaster(app.$store.getters.getUserInfo)
                    ? ['$all', { employee: ['email', 'full_name'] }]
                    : ['$all', { user: ['email', 'full_name'] }, { company: ['$all'] }],
                order: [['created_at', 'desc']],
                page: payload?.page || state.usersPagination.page,
                limit: state.LoginHistoryPagination.pageSize,
            };

            if (payload?.field && payload?.search) {
                params.where = {
                    [checkIsMaster(app.$store.getters.getUserInfo)
                        ? `$employee.${payload?.field}$`
                        : `$user.${payload?.field}$`]: { $iLike: `%${payload?.search}%` },
                };
            }

            const res = await CmsLoginHistory.getList(params);

            let users = [];
            let totalItems = 0;
            let totalPages = 0;
            if (res?.results?.objects?.rows) {
                users = res?.results?.objects?.rows;
                totalItems = res?.pagination?.total;
                totalPages = Math.ceil(totalItems / state.LoginHistoryPagination.pageSize);
            }

            commit('SET_LOGIN_HISTORY', users);
            commit('SET_LOGIN_HISTORY_PAGINATION', {
                ...state.LoginHistoryPagination,
                ...{ page: res?.pagination?.current_page, totalItems, totalPages },
            });
            commit('SET_IS_LOADING', false);
        } catch (err) {
            console.log(err);
            // return { isSuccess: false, data: err.response.data };
        } finally {
            commit('SET_IS_LOADING', false);
        }
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
