import API from './api';
import { exportResults } from '../utils/index';
import { HTTP } from '../config/http-common';

export default class uploadExcelApi extends API {
    getDownloadUser = async () => exportResults(await HTTP.post(`${this.ROUTE}/download_user`));

    getDownloadUsersample = async () => exportResults(await HTTP.post(`${this.ROUTE}/download_template_user`));

    uploadExcel = async (body) => exportResults(await HTTP.post(`file/upload_user`, body));

    uploadTest = async (body) => exportResults(await HTTP.post(`${this.ROUTE}/upload_test`, body));

    downloadTemplateTest = async () => exportResults(await HTTP.post(`${this.ROUTE}/download_template_test`));
}
